@import "variables";
.pricingpage{
	.top-banner{
		background: $pricing-top-banner-linear-gradient;
		padding: 50px 0 135px;
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 42px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
			}
		}
		@media(max-width: 1199px){
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
			}
		}
		@media(max-width: 991px){
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 135px;
			.banner-caption{
				text-align: center;
				margin: 30px 0 15px;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
			}
		}
	}
	.pricing-content-holder{
		padding: 0 0 75px;
		.rental-selection-box{
			margin: 0;
			padding: 75px 30px 150px;
			background: $rental-selection-box-bg;
			box-shadow: 0px 20px 50px $rental-selection-box-shadow;
			border-radius: 10px;
			position: relative;
			top: -130px;
			margin-bottom: -130px;
			.step{
				width: 80px;
				height: 36px;
				background: $step-bg;
				border-radius: 20px;
				font-size: 14px;
				font-weight: 700;
				line-height: normal;
				color: $step-color;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 0 15px;
			}
			h4{
				font-size: 18px;
				color: $white-color;
				font-weight: normal;
				margin: 0 0 30px;
			}
			.rental-quantity{
        		display: flex;
        		gap: 15px;
        		align-items: center;
        		.rental_value{      
					position: relative;  			
					input{
						display: flex;
						align-items: center;
						justify-content: flex-end;
			    		width: 80px;
						height: 40px;
						color: $white-color;
						font-size: 16px;
						font-weight: 700;
						border: 1px solid rgba(255, 255, 255, 0.5);
						border-radius: 8px;
						background: transparent;
						text-align: right;
						padding: 0 20px;
						text-align: center;
					}
					input {
						outline: none;
						-moz-appearance: textfield;
					}
					input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					}
					.plus-btn{
						position: absolute;
						right: 0;
						top: 2px;
						color: $range-slider-thumb;
						cursor: pointer;
						font-size: 24px;
					}
					.minus-btn{
						position: absolute;
						left: 0;
						top: 2px;
						color: $range-slider-thumb;
						cursor: pointer;
						font-size: 24px;
					}
        		}
        		input[type="range"]{
			    	-webkit-appearance: none; 
				    appearance: none;
				    width: 100%;
				    min-width: 150px;
				    height: 4px; 
				    -webkit-appearance: none;
				    -moz-apperance: none;
				    outline: 0;
				    background-image: -webkit-gradient(
				        linear,
				        left top,
				        right top,
				        color-stop(0, $range-slider-thumb),
				        color-stop(0, $range-slider-bg)
				    );
				}

				input[type='range']::-webkit-slider-thumb {
				    -webkit-appearance: none; 
				    appearance: none;
				    width: 24px; 
				    height: 24px; 
				    background: url('../images/icons/range-thumb.png') no-repeat center $range-slider-thumb;
				    cursor: pointer; 
				    outline: 0;
				    border-radius: 50%;
				}
				.range-slider::-moz-range-thumb {
				    width: 24px; 
				    height: 24px; 
				    background: $range-slider-thumb; 
				    cursor: pointer; 
				    border-radius: 50%;
				    outline: 0;
				}
        	}
        	.radio-box-holder{
				height: 40px;
				border-radius: 8px;
				display: flex;
				overflow: hidden;
				font-size: 14px;
				font-weight: 700;
				width: 275px;
				color: $white-color;
				.radio-box {		
					width: 50%;
					text-align: center;
					label{
						display : block;
						border-radius: 8px;
					    input{
						    visibility: hidden;
						    position: absolute;
						}
						input + div{ 
						    cursor:pointer;
							height: 40px;
							line-height: 40px;
							background-color: $radio-box-bg;
							opacity: 0.6;
							.bi-check-lg{
								display: none;
								margin-right: 5px;
							}
						}
						input:checked + div{
						    background-color: $radio-box-checked;
						    color: $white-color;
						    opacity: 1;
						    .bi-check-lg{
								display: inline-block;
							}
						}
					}			    
				}
			}
			.dropdown-currency-list{
				.btn-currency{
					height: 40px;
					width: 153px;
					border: 1px solid rgba(255, 255, 255, 0.5);
					border-radius: 8px;
					background-color: transparent;
					font-size: 14px;
					color: $white-color;
					text-align: left;
					font-weight: 700;
					padding: 0 20px;
					position: relative;
					.d-icon{
						color: $white-color;
						font-size: 12px;
						position: absolute;
						right: 20px;
						top: 10px;
					}
				}
				.currency-list{
					min-width: 153px;
					li{
						a{
							padding: 5px 20px;
							font-size: 14px;
							color: $black-color;
							display: block;
							font-weight: 700;
						}
					}
				}
			}
		}
		.pricing-box{
			display: flex;
			padding: 40px 30px;
			background: #444652;
			border-radius: 10px;
			position: relative;
			top: -90px;
			margin-bottom: -50px;
			align-items: flex-end;
			justify-content: space-around;
			color: $white-color;
			h4{
				font-weight: 700;
				font-size: 14px;
				opacity: .8;
				margin: 0 0 10px;
				text-transform: uppercase;
			}
			h1{
				font-size: 40px;
				font-weight: 700;
				margin: 0 0 10px;
			}
			p{
				font-weight: 400;
				font-size: 14px;
				opacity: .8;
				margin: 0;
				text-transform: uppercase;
			}
		}
		.content-holder{
			margin-bottom: 15px;			
			.free-trail-box{
				height: 100%;
				padding: 30px;
				border: 1px solid $default-border;
				h4{
					font-size: 36px;
					font-weight: 700;
					margin: 0;
					text-align: center;
				}
				.btn-flx-box, .flx-box{
					display: flex;
					justify-content: center;
				}
				.feature-list{
					margin: 30px 0 10px;
					padding: 0;
					list-style: none;
					li{						
						background: url('../images/icons/check-circle.png') no-repeat left center;
						margin: 0 0 20px;
						font-size: 14px;
						font-weight: 700;
						padding-left: 30px;
					}
					li.disabled{
						text-decoration: line-through;
						color: $gray;
					}
				}
				.btn-book-demo{
                    max-width: 300px;
                    width: 100%;
                    height: 60px;
                    background: $book-demo-btn-bg;
                    border-radius: 5px;
                    color: $white-color;
                    font-weight: 700;
                    font-size: 18px;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-position: center;
                    border: 1px solid $book-demo-btn-bg;
                    transition: background 0.8s;
                }
                .btn-book-demo:hover {
                  background: $ripple-btn-dflt-hvr;
                  color: $book-demo-btn-bg;
                }
                .btn-book-demo:active {
                  background-color: transparent;
                  background-size: 100%;
                  transition: background 0s;
                }
			}
			.features-box{
				height: 100%;
				padding: 30px;
				border: 1px solid $default-border;
				h4{
					font-size: 22px;
					font-weight: 700;
					margin: 0;
				}
				.feature-list{
					margin: 30px 0 66px;
					padding: 0;
					list-style: none;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					@media(max-width: 991px){						
						margin: 30px 0;
					}
					@media(max-width: 574px){
						grid-template-columns: repeat(1, 1fr);
					}
					li{						
						background: url('../images/icons/check-circle.png') no-repeat left center;
						margin: 0 0 20px;
						font-size: 14px;
						font-weight: 700;
						padding-left: 30px;
					}
					li.disabled{
						text-decoration: line-through;
						color: $gray;
					}
				}
				.btn-start-trial{
                    width: 300px;
                    height: 60px;
                    background: $black-color;
                    border-radius: 5px;
                    color: $white-color;
                    font-weight: 700;
                    font-size: 18px;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-position: center;
                    border: 1px solid $black-color;
                    transition: background 0.8s;
                }
                .btn-start-trial:hover {
                  background: $ripple-btn-dflt-hvr;
                  color: $black-color;
                }
                .btn-start-trial:active {
                  background-color: transparent;
                  background-size: 100%;
                  transition: background 0s;
                }
				.txt-info{
					font-size: 14px;
					font-style: italic;
					font-weight: normal;
					opacity: .8;
					margin: 20px 0 0;
				}
			}
			.prices-table-wrapper{
				height: 100%;
				border: 1px solid $default-border;
				.table{
					margin: 0;
					tbody{
						tr.table-header{
							background-color: #e9ecef;
							th{
								padding: 15px;
								vertical-align: middle;
								font-weight: 700;
								font-size: 14px;
								text-transform: uppercase;
							}
						}
						tr{
							td{
								padding: 5px 15px;
								vertical-align: middle;
								font-weight: 700;
								font-size: 14px;
							}
						}
						tr:last-child{
							td{
								border: 0;
							}
						}
					}
				}
			}
		}
		@media(min-width: 1400px){
			.rental-selection-box{
				.step{
					font-size: 16px;
				}
				h4{
					font-size: 22px;
				}
			}
			.pricing-box{
				h1{
					font-size: 50px;
				}
				p{
					font-size: 16px;
				}
			}
			.content-holder{		
				.free-trail-box{
					.feature-list{
						li{					
							font-size: 16px;
						}
					}
					.btn-book-demo{
	                    font-size: 22px;
	                    height: 70px;
	                }
				}
				.features-box{
					h4{
						font-size: 24px;
					}
					.feature-list{
						li{						
							font-size: 16px;
						}
					}
					.btn-start-trial{
	                    width: 320px;
	                    height: 70px;
	                    font-size: 22px;
	                }
					.txt-info{
						font-size: 16px;
					}
				}
			}
		}
		@media(max-width: 1199px){		
			padding: 0 0 50px;	
			.rental-selection-box{
				padding: 30px 15px 150px;
				.step{
					margin: 30px 0 15px;
				}
			}
			.pricing-box{
				h1{
					font-size: 36px;
				}
			}
		}
		@media(max-width: 991px){
			.pricing-box{
				padding: 30px;
				h1{
					font-size: 31px;
				}
				p{
					font-size: 12px;
				}
			}
			.content-holder{	
				.free-trail-box{
					h4{
						font-size: 31px;
					}
					.btn-book-demo{
	                    font-size: 16px;
	                }
				}
				.features-box{
					.btn-start-trial{
	                    font-size: 16px;
	                }
				}
			}
		}
		@media(max-width: 767px){
			.rental-selection-box{
				padding: 0 15px 85px;
			}
			.pricing-box{
				padding: 20px;
				top: -50px;
				margin-bottom: -15px;
				h1{
					font-size: 24px;
				}
			}
			.content-holder{	
				.free-trail-box{
					h4{
						font-size: 22px;
					}
					.btn-book-demo{
	                    height: 50px;
	                    max-width: 220px;
	                }
				}
				.features-box{
					.btn-start-trial{
	                    height: 50px;
	                    width: 250px;
	                }
				}
			}
		}
		@media(max-width: 574px){
			padding: 0 0 30px;
			.rental-selection-box{
	        	.radio-box-holder{
					width: 250px;
				}
			}
		}
	}
}