@import "variables";
.navbar.navbar-main.add-border{	
	border-bottom: 1px solid $default-border;
}
.notice-holder{
	background: $notice-bar-bg;
	color: $white-color;
	display: flex;
	width: 100%;
	min-height: 40px;
	align-items: center;
	justify-content: center;
	.flx-box{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0;
		gap: 10px;
		p{
			font-size: 14px;
			margin: 0;
			font-weight: 700;
			line-height: normal;
			a{
				color: $white-color;
				text-decoration: underline;
			}
		}
		.btn-x-close{
			background-color: transparent;
			border: 0;
			color: $white-color;
			font-size: 18px;
			padding: 0;
			opacity: .8;
			position: absolute;
			right: 15px;
			&:hover{
				opacity: 1;
			}
		}		
		@media(max-width: 767px){
			.btn-x-close{
				top: 5px;
			}
		}
	}
}
.navbar.navbar-main{
	background-color: $navbar-bg;
	border-bottom: 1px solid $navbar-bg;
	padding: 0;
	flex-wrap: wrap;
	@media(min-width: 992px){
		.navbar-brand, .btn-offcanvas-close{
			display: none;
		}
	}
	.main-container{
		position: relative;
		width: 100%;
		.container{
			align-items: center;
			display: flex;
			flex-wrap: inherit;
			justify-content: space-between;
		}
	}
	.offcanvas{
		.d-flex{
			min-height: 70px;
			padding: 10px 0;
			.logo-holder{
				img{
					max-height: 40px;
				}
			}
			.nav-holder{
				.navbar-nav{
					li{
						margin: 0 5px;
						a{
							padding: 10px 15px;
							color: $nav-color;
							font-weight: 400;
							display: block;
							font-size: 16px;
							text-transform: capitalize;
							-webkit-transition: color 50ms ease-out;
    						transition: color 50ms ease-out;
							&:hover{
								color: $nav-hvr-color;
							}
						}
					}
					li.active{
						a{
							color: $nav-color;
							font-weight: 700;
							&:hover{
								color: $nav-color;
							}
						}
					}
					li.dropdown{
						.dropdown-menu{
						    margin-top: 14px;
						    width: 800px;
						    border-radius: 0 0 5px 5px;
						    border: 0;
						    box-shadow: 0px 20px 40px $dropdown-menu-shadow;
						    background: $white-color;
						    padding: 20px 30px;
						    @media(min-width: 1400px){
						    	margin-top: 18px;
						    	width: 850px;
						    }	
						    .menu-container{
						    	padding: 5px;
						    	background-color: $white-color;
						    	-webkit-transition: background-color 50ms ease-out;
    							transition: background-color 50ms ease-out;
						    	a{
						    		display: block;
						    		padding: 15px;
						    		transition: background .3s;
						    		&:hover{
						    			background: $dropdown-menu-hover;
										border-radius: 4px;
						    		}
						    		.pdt-logo{
						    			margin-bottom: 10px;
						    			img{
						    				max-height: 30px;
						    			}
						    		}
						    		p{
						    			font-size: 14px;
						    			line-height: 20px;
										font-weight: 500;
										color: $default-color;
										opacity: 0.5;
										margin: 0;
										text-transform: none;
						    		}
						    	}
						    	a.d-flex{
						    		gap: 20px;
						    		.menu-icon{
						    			min-width: 49px;
						    			height: 49px;
										background: $dropdown-menu-icon-bg;
										border-radius: 4px;
										display: flex;
										justify-content: center;
										align-items: center;
										img{
											max-width: 45px;
											max-height: 45px;
										}
						    		}
						    		.menu-content{
						    			h5{
						    				color: $black-color;
						    				font-weight: 700;
						    				font-size: 16px;
						    				margin: 0 0 10px;
						    			}
						    			p{
						    				margin: 0;
						    			}
						    		}
						    	}
						    }						    					    
						    @media(max-width: 1199px){
						    	width: 650px;
						    	.menu-container{
							    	a{
							    		padding: 10px;
							    		.pdt-logo{
							    			margin-bottom: 5px;
							    			img{
							    				max-height: 25px;
							    			}
							    		}
							    		p{
							    			font-size: 12px;
							    		}
							    	}
							    	a.d-flex{
							    		gap: 15px;
							    		.menu-icon{
							    			min-width: 45px;
							    			height: 45px;
											img{
												max-width: 40px;
												max-height: 40px;
											}
							    		}
							    		.menu-content{
							    			h5{
							    				font-size: 14px;
							    				margin: 0 0 5px;
							    			}
							    		}
							    	}
							    }
						    }
						}
						.dropdown-menu.dropdown-menu-xs{
							width: 200px;
							padding: 0;
							ul{
								list-style: none;
								padding: 0;
								margin: 0;
							}
						}
						.dropdown-menu::after{
						    position: absolute;
						    content: "";
						    height: 2px;
						    width: 115px;
						    background: $nav-hvr-color;
						    left: 0;
						    top: -2px;
						}
					}
				}
			}
			.btn-holder{
				display: flex;
				gap: 10px;
				a{
					font-weight: 700;
					font-size: 16px;
					border: 1px solid $anchor-color;
					padding: 8px 15px;
					border-radius: 5px;
					background-color: $anchor-color;
					display: inline-block;
					color: $white-color;
					background-position: center;
			      	transition: background 0.8s;
				}				
				a.login-btn{
					background: $white-color;
					color: $anchor-color;
					background-position: center;
			      	transition: background 0.8s;
				}
			    a:hover , a.login-btn:hover{
			      background: $try-for-free-btn-hvr;
			      color: $white-color;
			    }
			    a:active, a.login-btn:active{
			      background-color: transparent;
			      background-size: 100%;
			      transition: background 0s;
			    }
			}
			@media(min-width: 1400px){
				min-height: 80px;
				padding: 15px 0;
				.logo-holder{
					img{
						max-height: 41px;
					}
				}
				.nav-holder{
					.navbar-nav{
						li{
							margin: 0 10px;
							a{
								padding: 10px 15px;
								font-size: 18px;
							}
						}
					}
				}
				.btn-holder{
					gap: 15px;
					a{
						padding: 10px 15px;
						font-size: 18px;
					}
				}
			}
			@media(max-width: 1199px){
				.nav-holder{
					.navbar-nav{
						li{
							a{
								padding: 10px 5px;
							}
						}
					}
				}
				.btn-holder{
					a{
						padding: 8px 10px;
					}
				}
			}
		}
		.d-flex.secondary-nav-holder{
			padding: 0;
			min-height: auto;
			display: none !important;
			&:before{
				width: 100%;
				height: 1px;
				background: $default-border;
				content: "";
				position: absolute;
				top: 71px;
			}
			@media(max-width: 991px){
				display: none !important;
			}
			.nav-holder.secondary-nav{
				.navbar-nav{
					li{
						margin: 0;
						a{
							padding: 15px 10px;
							color: $nav-color;
							font-weight: 400;
							display: block;
							font-size: 16px;
							text-transform: capitalize;
							-webkit-transition: color 50ms ease-out;
    						transition: color 50ms ease-out;
							&:hover{
								color: $nav-hvr-color;
							}
							@media(max-width: 1199px){
								padding: 12px 6px;
								font-size: 14px;
							}
						}
					}
					li.active{
						a{
							color: $nav-color;
							font-weight: 700;
							position: relative;
							&:hover{
								color: $nav-color;
							}
							&:before{
								content: "";
							    background: #1aa99d;
							    height: 2px;
							    position: absolute;
							    bottom: -1px;
							    width: 100%;
							    left: 0;
							}
						}
					}
				}
			}
		}
	}
	@media(max-width: 991px){		
		.main-container{
			padding: 5px 0;
		}
		.navbar-brand{
			img{
				max-height: 35px;
			}
		}
		.navbar-toggler {
		    background: $nav-toggler-bg;
		    color: $nav-hvr-color;
		    border: 1px solid $nav-hvr-color;
		    height: 36px;
		    width: 36px;
		    padding: 0;
		    border-radius: 50%;
		    outline: 0;
		    font-size: 24px;
		    &:focus{
		    	box-shadow: none;
		    }
		}
		.offcanvas{		
			overflow-y: auto;	
			.btn-offcanvas-close{
				width: auto;
			    height: auto;
			    position: fixed;
			    right: 12px;
			    padding: 0;
			    top: 12px;
			    line-height: normal;
			    font-size: 21px;
			    color: $nav-color;
			    background-color: $white-color;
			    opacity: .7;
			    border: 0;
			    z-index: 1;
			    &:hover{
			    	opacity: 1;
			    }

			}
			.d-flex{
				display: block !important;
				.logo-holder{
					padding: 5px 15px 15px;
				}
				.nav-holder{
					.navbar-nav{
						li{
							border-top: 1px solid $default-border;
							margin: 0;
							a{
								padding: 10px 15px;
								display: block;
							}
						}
						li.dropdown{
							.dropdown-menu{
							    margin-top: 0;
							    width: 100%;
							    box-shadow: none;
							    padding: 0;
							    .row{
							    	margin: 0;
							    }
							    .menu-container{
							    	padding: 0;
							    	a{
							    		padding: 10px 15px;
							    		&:hover{
											border-radius: 0;
							    		}
							    		.pdt-logo{
							    			margin-bottom: 5px;
							    			img{
							    				max-height: 20px;
							    			}
							    		}
							    		p{
							    			font-size: 14px;
							    			line-height: normal;
							    		}
							    	}
							    	a.d-flex{
							    		display: flex !important;
							    		align-items: center;
							    		gap: 15px;
							    		.menu-icon{
							    			min-width: 45px;
							    			height: 45px;
											img{
												max-width: 40px;
												max-height: 40px;
											}
							    		}
							    		.menu-content{
							    			h5{
							    				font-size: 14px;
							    				margin: 0 0 2.5px;
							    			}
							    		}
							    	}
							    }
							}
							.dropdown-menu.dropdown-menu-xs{
								width: 100%;
								padding: 0;
								ul{
									list-style: none;
									padding: 0;
									margin: 0;
									li{
										border: 0;
									}
								}
							}
							.dropdown-menu::after{
							    top: 45px;
							}
						}
					}
				}
				.btn-holder{
					border-top: 1px solid $default-border;
					padding: 10px 15px;
					a{
						padding: 5px 20px;
					}
				}
			}
		}
	}
	@media(max-width: 767px){
		.navbar-toggler-mobile{
			background: $white-color;
			border: 0;
			img{
				width: 28px;
			}
			&:focus{
				border: 0;
				box-shadow: none;
				outline: none;
			}
		}
		.offcanvas.offcanvas-bottom {
			right: 0;
			top: 55px;
			left: 0;
			bottom: 0;
			height: auto;
			border-top-left-radius: 43px;
			border-top-right-radius: 43px;
			border: 0;
			box-shadow: none;
			text-align: center;
			.offcanvas-header{				
				padding: 35px 20px 15px;
				justify-content: center;
				.offcanvas-title{
					text-transform: uppercase;
					font-weight: 400;
					opacity: 0.4;
					font-size: 18px;
				}
				.btn-close{
					position: absolute;
					right: 35px;
					top: 35px;
					z-index: 1;
					font-size: 18px;
					outline: 0;
					&:focus{
						border: 0;
						outline: 0;
						box-shadow: none;
					}
				}				
			}
			.offcanvas-body{
				padding: 0;
				.nav-xs{
					padding: 0;
					margin: 0 0 30px;
					list-style: none;
					li{
						a{
							display: block;
							font-size: 20px;
							font-weight: 400;
							color: $default-color;
							padding: 12px 30px;
						}
					}
				}
				.btn-holder{
					margin: 0 0 30px;
					padding: 0 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 15px;
					.btn{
						background: $anchor-color;
						width: 214px;
						height: 45px;
						color: $white-color;
						font-weight: 700;
						border-radius: 5px;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						border: 0;
					}				
					a.login-btn{
						background: $white-color;
						border: 1px solid $anchor-color !important;
						width: 214px;
						height: 45px;
						color: $anchor-color;
						font-weight: 700;
						border-radius: 5px;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						border: 0;
					}
				}
			}
			.submenu-holder{
				background: $white-color;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				border-top-left-radius: 43px;
				border-top-right-radius: 43px;
				z-index: 1;
				transition: all 50ms ease-out;
				.menu-header{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 28px 31px 15px;
					.btn-back{
						background: transparent;
						font-size: 21px;
						border: 0;
						padding: 0;
						opacity: .8;
						color: $default-color;
						&:focus{
							color: $default-color;
						}
						&:hover{
							color: $default-color;
						}
					}
					.menu-title{
						text-transform: uppercase;
						font-weight: 400;
						opacity: 0.4;
						font-size: 18px;
						margin: 0;
					}
					.btn-close{
						font-size: 18px;
						outline: 0;
						&:focus{
							border: 0;
							outline: 0;
							box-shadow: none;
						}
					}	
				}
				.menu-body{
					position: absolute;
					top: 77px;
					left: 0;
					right: 0;
					bottom: 0;
					overflow-y: auto;
					padding: 0 30px;
					.menu-container{
						margin-bottom: 20px;
						text-align: left;
						.d-flex{
							display: flex !important;
							gap: 20px;
							text-align: left;
							.menu-icon{
								min-width: 49px;
								height: 49px;
								background: $dropdown-menu-icon-bg;
								border-radius: 4px;
								display: flex;
								justify-content: center;
								align-items: center;
								img{
									max-width: 45px;
									max-height: 45px;
								}
							}
							.menu-content{
								h5{
									color: $black-color;
									font-weight: 700;
									font-size: 16px;
									margin: 0 0 5px;
								}
								p{
									margin: 0;
									font-size: 14px;
									color: $default-color;
									opacity: 0.5;
								}
							}
						}
						.pdt-logo{
							margin-bottom: 10px;
							img{
								max-height: 30px;
							}
						}
						p{
							font-size: 14px;
							color: $default-color;
							opacity: 0.5;
							margin: 0 0 30px;
						}
					}
					ul{
						padding: 0;
						margin: 0 0 30px;
						list-style: none;
						li{
							a{
								display: block;
								font-size: 20px;
								font-weight: 400;
								color: $default-color;
								padding: 12px 30px;
							}
						}
					}
				}
			}
		}
	}

	// Animation for dropdown
	@media (min-width: 992px) {
	  .animate {
	    animation-duration: 0.3s;
	    -webkit-animation-duration: 0.3s;
	    animation-fill-mode: both;
	    -webkit-animation-fill-mode: both;
	  }
	}
	@keyframes slideIn {
	  0% {
	    transform: translateY(1rem);
	    opacity: 0;
	  }

	  100% {
	    transform: translateY(0rem);
	    opacity: 1;
	  }

	  0% {
	    transform: translateY(1rem);
	    opacity: 0;
	  }
	}

	@-webkit-keyframes slideIn {
	  0% {
	    -webkit-transform: transform;
	    -webkit-opacity: 0;
	  }

	  100% {
	    -webkit-transform: translateY(0);
	    -webkit-opacity: 1;
	  }

	  0% {
	    -webkit-transform: translateY(1rem);
	    -webkit-opacity: 0;
	  }
	}
	.slideIn {
	  -webkit-animation-name: slideIn;
	  animation-name: slideIn;
	}
}
body.solutions-template{
	.navbar.navbar-main {
		.offcanvas {
			.d-flex{
				.nav-holder{
					.navbar-nav{
						li{
							a.active-solutions{
								color: $nav-color;
								font-weight: 700;
								&:hover{
									color: $nav-color;
								}
							}
						}
					}
				}
			}
			.d-flex.secondary-nav-holder{					
				display: flex !important;
				@media(max-width: 991px){
					display: none !important;
				}
			}
		}
	}
}