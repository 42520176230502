@import "variables";
.about-page{
	.top-banner{
		background: $about-top-banner-bg;
		padding: 50px 0 100px;	
		@media(max-width: 767px){
			padding: 30px 0 0;
		}
	}
	.about-content-holder{
		padding: 0 0 50px;
		background: $integrations-content-linear-gradient;
		.dark-box-top{
			background: $dark-box-bg;
			padding: 55px 45px;
			border-radius: 5px;
			position: relative;
			top: -220px;
			margin-bottom: -180px;
			color: $white-color;
			.title-holder{
				margin: 0 0 15px;
				h4{
					font-size: 18px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0;
				}
			}
			.box-lhs{
				border-right: 1px solid $white-border-opacity;
				padding-right: 45px;
				h1{
					font-weight: 700;
					font-size: 45px;
					margin: 0 0 30px;
				}
				p{
					font-size: 20px;	
					margin: 0;				
				}
			}
			.box-rhs{
				padding-left: 45px;
				p{
					font-size: 16px;	
					margin: 0;				
				}
			}
		}
		.content-box{
			padding: 50px 0;
			.title-flx-box{
				display: flex;
				align-items: center;
				margin: 0 0 50px;
				h1{
					margin: 0;
					font-weight: 700;
					font-size: 40px;
					padding-right: 50px;
					border-right: 1px solid $black-border-opacity;
				}
				p{
					padding-left: 50px;
					margin: 0;
					font-size: 18px;
				}
			}
			.content{
				p{
					font-size: 16px;
					opacity: .8;
				}
				ul{
					list-style: none;
					margin: 50px 0 0;
					padding: 0;
					li{
						display: flex;
						margin: 0 0 20px;
						.icon-holder{
							min-width: 100px;
							img{
								max-width: 65px;
							}
						}
						.li-content{
							h4{
								font-size: 16px;
								font-weight: 700;
								margin: 0 0 15px;
							}
							p{
								font-size: 16px;
								opacity: .7;
								margin: 0;
							}
						}
					}
				}
			}
		}
		.content-box:nth-of-type(odd) {
			.img-holder{
				img{
					position: relative;
					left: -5%;
				}
			}
		}
		.content-box:nth-of-type(even) {
			.img-holder{
				img{
					position: relative;
					right: -5%;
				}
			}
		}
		@media(min-width: 1400px){
			.dark-box-top{
				.title-holder{
					margin: 0 0 20px;
					h4{
						font-size: 22px;
					}
				}
				.box-lhs{
					padding-right: 50px;
					h1{
						font-size: 50px;
					}
					p{
						font-size: 24px;				
					}
				}
				.box-rhs{
					padding-left: 50px;
					p{
						font-size: 18px;		
					}
				}
			}
			.content-box{
				.title-flx-box{
					h1{
						font-size: 45px;
					}
					p{
						font-size: 24px;
					}
				}
				.content{
					p{
						font-size: 18px;
					}
					ul{
						li{
							margin: 0 0 30px;
							.li-content{
								h4{
									font-size: 18px;
								}
								p{
									font-size: 18px;
								}
							}
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			.dark-box-top{
				.box-lhs{
					padding-right: 30px;
					h1{
						font-size: 36px;
					}
				}
				.box-rhs{
					padding-left: 30px;
				}
			}
			.content-box{
				.title-flx-box{
					h1{
						font-size: 36px;						
						padding-right: 30px;
					}
					p{						
						padding-left: 30px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.dark-box-top{
				.title-holder{
					h4{
						font-size: 16px;
					}
				}
				.box-lhs{
					h1{
						font-size: 28px;
					}
					p{
						font-size: 16px;				
					}
				}
				.box-rhs{
					p{
						font-size: 14px;		
					}
				}
			}
			.content-box{
				padding: 30px 0;
				.title-flx-box{
					h1{
						font-size: 28px;
					}
					p{
						font-size: 16px;
					}
				}
				.content{
					p{
						font-size: 16px;
					}
					ul{
						margin: 30px 0 0;
						li{
							margin: 0 0 15px;
							.icon-holder{
								min-width: 75px;
								img{
									max-width: 50px;
								}
							}
							.li-content{
								h4{
									font-size: 16px;
								}
								p{
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
		@media(max-width: 767px){
			padding: 0;
			.dark-box-top{
				padding: 30px 20px;
				top: -75px;
				margin-bottom: -75px;
				.box-lhs{
					border-right: 0;
					border-bottom: 1px solid $white-border-opacity;
					padding-right: 15px;
					padding-bottom: 20px;
					h1{
						font-size: 24px;
						margin: 0 0 15px;
					}
				}
				.box-rhs{
					padding-top: 15px;
					padding-left: 15px;
				}
			}
			.content-box{
				.title-flx-box{
					margin: 0 0 20px;
					display: block;
					h1{
						font-size: 24px;
						padding-right: 0;
						padding-bottom: 15px;
						border-right: 0;
						border-bottom: 1px solid $black-border-opacity;
					}
					p{
						padding-top: 15px;
						padding-left: 0;
						margin: 0;
						font-size: 16px;
					}
				}
			}
			.content-box:nth-of-type(even) {
				.img-holder{
					img{
						position: relative;
						right: 0;
						left: -5%;
					}
				}
			}
		}
	}
}
