@import "variables";
.privacy-terms-layout{
	.top-banner{
		background: $terms-bg;
		padding: 65px 0 300px;
		h1{
			font-size: 40px;
			font-weight: 700;
			margin: 0 0 15px;
		}
		h4{
			font-size: 18px;
			font-weight: 700;
			margin: 0 0 15px;
		}	
			
		@media(min-width: 1400px){
			h1{
				font-size: 45px;
			}
			h4{
				font-size: 20px;
			}	
		}
		@media(max-width: 991px){
			h1{
				font-size: 36px;
			}	
		}
		@media(max-width: 991px){
			h1{
				font-size: 31px;
			}
		}	
		@media(max-width: 767px){
			padding: 30px 0 160px;
			h1{
				font-size: 24px;
				margin: 0 0 10px;
			}
			h4{
				font-size: 16px;
			}	
		}
	}
	.content-holder{
		.container-box{
			background: $terms-container-bg;
			padding: 30px;
			position: relative;
			top: -275px;
			margin-bottom: -230px;
			.content-box{
				background: $white-color;
				padding: 30px;
				h2 {
					margin: 50px 0 20px;
					font-weight: 700;
					font-size: 21px;
				}	
				p, ul, ol {
					font-size: 16px;
				}
				ul, ol {
					li{
						margin-bottom: 15px;
					}
				}
			}
		}		
		@media(max-width: 767px){
			.container-box{
				padding: 15px;
				top: -150px;
				margin-bottom: -120px;
				.content-box{
					padding: 15px;
					h2 {
						margin: 50px 0 15px;
						font-weight: 700;
						font-size: 18px;
					}	
					p, ul, ol {
						font-size: 14px;
					}
					ul, ol {
						li{
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}	
}
