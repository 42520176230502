@import "variables";
.homepage{
	.top-banner{
		.banner{
			margin-top: 37px;
		}
		.banner-caption{
			top: 177px;
		}
	} 
	@media(max-width: 1199px){
		.top-banner{
			.banner-caption{
				top: 130px;
			}
		} 
	}
	@media(max-width: 991px){
		.top-banner{
			.banner-caption{
				top: 37px;
			}
		} 
	}
	@media(max-width: 767px){
		.navbar-main.sticky-top{
			top: 0;
		}
		.notice-holder{
			position: relative;
			.flx-box{
				p{
					font-size: 13px;
				}
			}
		}
		.top-banner{
			.banner-caption{
				top: 0;
			}
		} 
	}

	.top-banner{
		background: $homepage-top-banner-linear-gradient;
		padding: 50px 0 0;
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 40px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
			.btn-holder{
				margin: 45px 0 0;
				.watch-video-btn{
					width: 260px;
					height: 60px;
					background: $black-color;
					border-radius: 5px;
					color: $white-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $black-color;
					background-position: center;
      				transition: background 0.8s;
				}
			    .watch-video-btn:hover {
			      background: $ripple-btn-dflt-hvr;
                  color: $black-color;
			    }
			    .watch-video-btn:active {
			      background-color: transparent;
			      background-size: 100%;
			      transition: background 0s;
			    }
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
				.btn-holder{
					margin: 50px 0 0;
					.watch-video-btn{
						width: 280px;
						height: 70px;
						font-size: 21px;
					}
				}
			}
		}
		@media(max-width: 1199px){
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
				.btn-holder{
					margin: 30px 0 0;
					.watch-video-btn{
						width: 250px;
						height: 60px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
				.btn-holder{
					.watch-video-btn{
						width: 230px;
					    height: 50px;
					    font-size: 16px;
					}
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0;
			.banner-caption{
				text-align: center;
				margin-bottom: 30px;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
				.btn-holder{
					margin-top: 25px;
					.watch-video-btn{
						display: inline-flex;
						width: 200px;
					}
				}
			}
		}
	}
	.channels-holder{
		background: $channel-holder-bg;
		padding: 100px 0 75px;
		.channels-flx-log-box{
			display: flex;
			gap: 45px;
			align-items: center;
			justify-content: space-between;
			padding: 0 30px 75px;
			.logo-box{				
				img{
					max-width: 100%;
				}
				img:hover{
					opacity: .8;
				}
			}
		}
		.owners-box-holder{
			.box-holder{
				margin-bottom: 15px;
				.box{
					background: $white-color;
					padding: 50px 30px;
					border-radius: 5px;
					position: relative;
					height: 100%;
					.img-holder{
						margin-bottom: 50px;
						height: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							max-width: 100%;
							max-height: 100px;
						}
					}
					.box-content{
						h4{
							font-size: 18px;
							margin: 0 0 20px;
							font-weight: 700;
						}
						p{
							font-size: 16px;
							margin: 0;
						}
					}
				}
			}
		}
		@media(min-width: 1400px){
			.channels-flx-log-box{
				gap: 30px;
			}
			.owners-box-holder{
				.box-holder{
					.box{
						padding: 50px 40px;
						.img-holder{
							margin-bottom: 50px;
							height: 124px;
							img{
								max-height: 124px;
							}
						}
						.box-content{
							h4{
								font-size: 21px;
								margin: 0 0 30px;
							}
							p{
								font-size: 18px;
							}
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 50px 0 35px;
			.channels-flx-log-box{
				gap: 30px;
				padding: 0 15px 50px;
			}
			.owners-box-holder{
				.box-holder{
					.box{
						padding: 30px;
						.img-holder{
							margin-bottom: 30px;
							height: 80px;
							img{
								max-height: 80px;
							}
						}
						.box-content{
							h4{
								font-size: 16px;
								margin: 0 0 15px;
							}
							p{
								font-size: 14px;
							}
						}
					}
				}
			}
		}		
		@media(max-width: 991px){
			.channels-flx-log-box{
				gap: 15px;
				padding: 0 0 50px;
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0;
			.channels-flx-log-box{
				gap: 15px;
				padding: 0 0 30px;
				flex-wrap: wrap;
				justify-content: center;
				.logo-box{
					max-width: 29%;
				}
			}
		}
		@media(max-width: 574px){
			padding: 30px 0 0;
			.owners-box-holder{
				.box-holder{
					.box{
						padding: 30px;
						text-align: center;
					}
				}
			}
		}
	}
	.short-info-holder{
		padding: 100px 0;
		background: $white-color;
		.short-info{
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 130px;
			text-align: right;
			h4{
				font-size: 31px;
				margin: 0;
			}
			p{
				margin: 0;
				font-size: 16px;
				img{
					margin-left: 5px;
				}
			}
		}
		@media(min-width: 1400px){
			.short-info{
				gap: 150px;
				h4{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
			}
		}
		@media(max-width: 1199px){
			.short-info{
				gap: 100px;
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			.short-info{
				gap: 75px;
			}
		}
		@media(max-width: 767px){
			padding: 30px 0;
			.short-info{
				gap: 30px;
				flex-wrap: wrap;
				h4{
					font-size: 24px;
				}
				p{
					font-size: 14px;
				}
			}
		}
		@media(max-width: 574px){
			padding: 30px 0;
			.short-info{
				justify-content: space-around;
				.info{
					width: 40%;
					text-align: center;
				}
			}
		}

	}
	.our-products-holder{
		background: $product-holder-bg;
		padding: 100px 0 75px;
		color: $white-color;
		.title{			
			margin: 0 0 20px;
			h4{				
				font-size: 18px;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0;
			}
		}
		h1{
			font-size: 36px;
			font-weight: 700;
			margin: 0 0 30px;
			line-height: normal;
		}
		.nav-tab-holder{
			margin-bottom: 50px;
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			justify-content: space-between;
			.btn{
				width: 45%;
				padding: 10px 20px;
				border: 0;
				height: 50px;
				background: transparent;
				display: block;
				img{
					max-width: 100%;
					max-height: 30px;
				}
				.d-active{
					display: block;
				}
				.e-active{
					display: none;
				}
			}
			.btn.active{
				background: $white-color;
				.d-active{
					display: none;
				}
				.e-active{
					display: block;
				}
			}
		}
		.btn-holder{			
			.btn-visit-web{
				width: 240px;
				height: 60px;
				background: transparent;
				border-radius: 5px;
				color: $white-color;
				font-weight: 700;
				font-size: 18px;
				padding: 0 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 1px solid $white-color;
				background-position: center;
  				transition: background 0.8s;
			}
		    .btn-visit-web:hover {
		      background: $white-color;
              color: $anchor-color;
		    }
		}
		.tab-content{
			h4{
				font-size: 18px;
				margin: 0 0 20px;
				font-weight: 700;
				color: $white-color;
			}
			p{
				font-size: 16px;
			}
			.carousel{
				margin: 30px 0 45px;
				.carousel-indicators{
					margin-bottom: -35px;
				}
				.carousel-indicators [data-bs-target]{
					background-color: $white-color;
					width: 18px;
				}
				.carousel-control-prev {
					left: 0;
    				width: auto;
					opacity: 1;
				}
				.carousel-control-next {
					right: 0;
					width: auto;
					opacity: 1;
				}
				.carousel-control-prev-icon, .carousel-control-next-icon,
				.carousel-control-prev-icon:focus, .carousel-control-next-icon:focus
				{
					filter: invert(0) grayscale(0);
					background-color: $anchor-color;
					background-size: 20px;
					opacity: .7;
				}
				.carousel-control-prev-icon:hover, .carousel-control-next-icon:hover
				{
					opacity: .9;
				}
			}
		}
		@media(min-width: 1400px){
			.title{			
				margin: 0 0 30px;
				h4{				
					font-size: 24px;
				}
			}
			h1{
				font-size: 45px;
				margin: 0 0 50px;
			}
			.nav-tab-holder{
				margin-bottom: 75px;
				.btn{
					width: 45%;
					height: 60px;
					img{
						max-height: 40px;
					}
				}
			}
			.btn-holder{			
				.btn-visit-web{
					width: 260px;
					height: 70px;
					font-size: 22px;
				}
			}
			.tab-content{
				h4{
					font-size: 22px;
					margin: 0 0 30px;
				}
				p{
					font-size: 18px;
				}
			}
		}
		@media(max-width: 1199px){
			padding: 75px 0 50px;
			.title{			
				margin: 0 0 15px;
				h4{				
					font-size: 16px;
				}
			}
			h1{
				font-size: 31px;
				margin: 0 0 30px;
			}
			.nav-tab-holder{
				margin-bottom: 45px;
				.btn{
					padding: 10px 15px;
				}
			}
			.btn-holder{			
				.btn-visit-web{
					width: 230px;
					height: 50px;
					font-size: 16px;
				}
			}
			.tab-content{
				h4{
					font-size: 18px;
					margin: 0 0 15px;
				}
				p{
					font-size: 16px;
				}
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			.nav-tab-holder{
				gap: 15px;
				.btn{
					padding: 5px 10px;
					height: 45px;
					img{
						max-height: 25px;
					}
				}
			}
		}
		@media(max-width: 767px){
			padding: 30px 0;
			text-align: center;
			::-webkit-scrollbar {
				display: none;
			}
			.title{			
				margin: 0 0 10px;
				h4{				
					font-size: 14px;
				}
			}
			h1{
				font-size: 24px;
				margin: 0 0 30px;
			}
			.nav-tab-holder{
				margin-bottom: 30px;
				flex-wrap: nowrap;
				overflow-x: auto;
				.btn {
					min-width: fit-content;
					img {
						max-height: 25px;
						min-height: 25px;
					}
				}
			}
			.tab-content{
				h4{
					font-size: 16px;
					margin: 15px 0 10px;
				}
				p{
					font-size: 14px;
				}
				.carousel{
					margin: 0 0 35px;
				}
			}
			.btn-holder-flx{			
				margin: 15px 0;
				display: inline-flex !important;
			}
		}
	}
	.our-services-holder{
		background: $services-holder-bg;
		padding: 100px 0;
		.flx-log-box{
			display: flex;
			gap: 45px;
			align-items: center;
			justify-content: space-between;
			padding: 0 0 75px;
			.logo-box{				
				img{
					max-width: 100%;
				}
				img:hover{
					opacity: .8;
				}
			}
		}
		.title-holder{
			margin: 0 0 30px;
			h4{
				font-size: 18px;
				font-weight: 700;
				margin: 0 0 30px;
				color: $anchor-color;
				text-transform: uppercase;
			}
			h1{
				font-weight: 700;
				font-size: 36px;
				margin: 0;
			}
		}
		.flex-content-box{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 50px;
			margin: 50px 0 0;
			.box{
				width: 28%;
				display: flex;
				gap: 20px;
				.img-holder{
					img{
						max-width: 75px;
					}
				}
				.content-holder{
					h4{
						font-size: 18px;
						font-weight: 700;
						margin: 0 0 20px;
					}
					p{
						font-size: 16px;
					}
					.btn-holder{
						margin: 20px 0 0;
						a{
							font-size: 16px;
							font-weight: 700;
							display: flex;
	    					align-items: center;
							i{
								margin-left: 20px;
								font-size: 18px;
							}
						}
					}
				}
			}
		}
		.btn-see-all-services-holder{
			display: flex;
			justify-content: center;
			.btn{
                width: 260px;
                height: 60px;
                background: $black-color;
                border-radius: 5px;
                color: $white-color;
                font-weight: 700;
                font-size: 18px;
                padding: 0 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-position: center;
                border: 1px solid $black-color;
                transition: background 0.8s;
            }
            .btn:hover {
              background: $ripple-btn-dflt-hvr;
              color: $black-color;
            }
            .btn:active {
              background-color: transparent;
              background-size: 100%;
              transition: background 0s;
            }
		}
		@media(min-width: 1400px){
			.flx-log-box{
				gap: 30px;
			}
			.title-holder{
				h4{
					font-size: 24px;
				}
				h1{
					font-size: 45px;
				}
			}
			.flex-content-box{
				.box{
					gap: 30px;
					.content-holder{
						h4{
							font-size: 24px;
						}
						p{
							font-size: 18px;
						}
						.btn-holder{
							a{
								font-size: 18px;
								i{
									font-size: 20px;
								}
							}
						}
					}
				}
			}
			.btn-see-all-services-holder{
				.btn{
	                width: 280px;
	                height: 70px;
	                font-size: 22px;
	            }
			}
		}
		@media(max-width: 1199px){
			padding: 75px 0;
			.flx-log-box{
				gap: 30px;
				padding: 0 0 50px;
			}
			.title-holder{
				h4{
					font-size: 16px;
					margin: 0 0 15px;
				}
				h1{
					font-size: 31px;
				}
			}
			.flex-content-box{
				gap: 30px;
				margin: 50px 0 0;
				.box{
					gap: 15px;
					.img-holder{
						img{
							max-width: 50px;
						}
					}
				}
			}
			.btn-see-all-services-holder{
				.btn{
	                width: 250px;
	                font-size: 16px;
	            }
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			.flx-log-box{
				gap: 15px;
			}
			.flex-content-box{
				gap: 30px;
				margin: 50px 0 0;
				.box{
					width: 45%;
					gap: 15px;
				}
			}
		}
		@media(max-width: 767px){
			padding: 30px 0 50px;
			.flx-log-box{
				gap: 15px;
				padding: 0 0 30px;
				flex-wrap: wrap;
				justify-content: center;
				.logo-box{
					max-width: 29%;
				}
			}
			.title-holder{				
				text-align: center;
				h4{
					margin: 0 0 10px;
				}
				h1{
					font-size: 24px;
				}
			}
			.btn-see-all-services-holder{
				.btn{
	                height: 50px;
	            }
			}
		}
		@media(max-width: 574px){
			.flx-log-box{
				justify-content: space-between;
				.logo-box{
					margin-bottom: 15px;
					max-width: 45%;
					width: 45%;
				}
			}
			.flex-content-box{
				margin: 30px 0 0;
				text-align: center;
				.box{
					width: 100%;
					display: block;
					.img-holder{
						img{
							max-width: 75px;
						}
					}
					.content-holder{
						margin-top: 15px;
						h4{
							margin: 0 0 5px;
						}
						p{
							font-size: 16px;
						}
						.btn-holder{
							margin: 10px 0 0;
							a{
								display: inline-flex;
							}
						}
					}
				}
			}
		}
	}
	.testimonials-holder{
		background: $white-color;
		padding: 75px 0;
		.title-holder{
			margin-bottom: 50px;
			h4{
				font-size: 18px;
				font-weight: 700;
				margin: 0 0 30px;
				color: $anchor-color;
				text-transform: uppercase;
			}
			h1{
				font-weight: 700;
				font-size: 36px;
				margin: 0;
			}
		}
		.total-reviews{
			margin-bottom: 50px;
			.review-box{
				a{
					display: inline-block;
					img{
						max-width: 100%;
					}
					&:hover{
						img{
							opacity: .8;
						}
					}
				}
			}
		}
		.carousel{
			position: relative;
			margin: 0 0 30px;
			.carousel-indicators{
				margin-bottom: -50px;
			}
			.carousel-indicators [data-bs-target]{
				background-color: $indicators-gray;
				width: 20px;
				border-radius: 4px;
				height: 4px;
			}
			.carousel-indicators .active[data-bs-target]{
				background-color: $anchor-color2;
			}
			.carousel-item{
				.testimonial-holder{
					height: 100%;
					border: 1px solid $default-border;
					padding: 45px 30px 30px 80px;
					background: url('../images/testimonials/comments.png') no-repeat $white-color;
					background-position: 30px 30px;
					position: relative;
					&:before{
						position: absolute;
						content: "";
						left: -1px;
						top: 30px;
						height: 35px;
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
						width: 6px;
						background: $anchor-color2;
					}
					p{
						font-size: 14px;
						margin: 0 0 20px;
						a{
							font-weight: 700;
						}
					}
					.author-flx-box{
						display: flex;
						gap: 100px;
						align-items: center;
						.auth{
							display: flex;
							align-items: center;
							gap: 10px;
							img{
								width: 35px;
								height: 35px;
								border-radius: 50%;
							}
							span{
								font-weight: 700;
								font-size: 14px;
							}
						}
						.company{
							img{
								max-height: 30px;
							}
						}
					}
				}
			}
		}
		@media(min-width: 1400px){
			.title-holder{
				h4{
					font-size: 24px;
				}
				h1{
					font-size: 45px;
				}
			}
			.carousel{
				.carousel-item{
					.testimonial-holder{
						p{
							font-size: 16px;
						}
						.author-flx-box{
							.auth{
								span{
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 50px 0;
			.title-holder{
				margin-bottom: 30px;
				h4{
					font-size: 16px;
					margin: 0 0 15px;
				}
				h1{
					font-size: 31px;
				}
			}
			.carousel{
				.carousel-item{
					.testimonial-holder{
						.author-flx-box{
							gap: 30px;
						}
					}
				}
			}
		}
		@media(max-width: 991px){
			.total-reviews{
				margin-bottom: 30px;
			}
			.carousel{
				.carousel-item{
					.testimonial-holder{
						padding: 30px 20px 20px 50px;
						background-position: 10px 15px;
						position: relative;
						&:before{
							top: 20px;
						}
						.author-flx-box{
							gap: 15px;
							.company{
								img{
									max-width: 100%;
									max-height: 25px;
								}
							}
						}
					}
				}
			}
		}
		@media(max-width: 767px){
			padding: 30px 0;
			.title-holder{
				text-align: center;
				margin-bottom: 30px;
				h4{
					font-size: 16px;
					margin: 0 0 10px;
				}
				h1{
					font-size: 24px;
				}
			}
			.total-reviews{
				margin-bottom: 15px;
				.review-box{
					margin-bottom: 15px;
				}
			}
			.carousel{
				margin: 0 0 20px;
				.carousel-indicators{
					margin-bottom: -35px;
				}
				p{
					font-size: 12px;
					margin: 0 0 15px;
				}
				.author-flx-box{
					.auth{
						span{
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}
