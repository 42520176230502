@import "variables";
.solutions-template{
	.top-banner{
		background: $solutions-temp-top-banner-bg;
		padding: 75px 0 150px;
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 42px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
			.btn-holder{
				margin: 45px 0;
				display: flex;
				gap: 15px;
				position: relative;
				.btn-start-trial{
					height: 60px;
					background: transparent;
					border-radius: 5px;
					color: $black-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;					
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $black-color;
					background-position: center;
      				transition: background 0.8s;
				}
				.btn-start-trial:hover {
					background: $black-color;
					color: $white-color;
				}
				.btn-info-text-absolute{
					position: absolute;
					top: 70px;
					font-size: 14px;
					font-weight: 700;
					font-style: italic;
				}
				.watch-video-btn{
					width: 230px;
					height: 60px;
					background: $black-color;
					border-radius: 5px;
					color: $white-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $black-color;
					background-position: center;
  				transition: background 0.8s;
				}
		    	.watch-video-btn:hover {
		      		background: $ripple-btn-dflt-hvr;
          			color: $black-color;
		    	}
				.watch-video-btn:active {
				background-color: transparent;
				background-size: 100%;
				transition: background 0s;
				}
			}
		}
		.banner-holder{
			img{
				position: relative;
				right: -5%;
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
				.btn-holder{
					margin: 50px 0;
					.btn-start-trial{						
						height: 70px;
						font-size: 21px;
					}
					.btn-info-text-absolute{
				    top: 80px;
				    font-size: 16px;
			    }
					.watch-video-btn{
						width: 250px;
						height: 70px;
						font-size: 21px;
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 50px 0 150px;
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
				.btn-holder{
					margin: 30px 0;
					.btn-start-trial, .watch-video-btn{
						height: 60px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
				.btn-holder{
					.btn-start-trial, .watch-video-btn{
					    height: 50px;
					    font-size: 16px;
					}
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 100px;
			.banner-caption{
				text-align: center;
				margin: 30px 0 0;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
				.btn-holder{
					.watch-video-btn{
						display: inline-flex;
						width: 200px;
					}
				}
			}
			.banner-holder{
				img{
					right: 0;
				}
			}
		}		
		@media(max-width: 574px){
			.banner-caption{
				.btn-holder{
					margin: 20px 0;
					display: block;
					.btn{
						margin-bottom: 10px;
					}					
					.btn-info-text-absolute{
				    position: relative;
				    display: block;
				    top: -5px;
				    margin-bottom: 10px;
			    }
				}
			}
		}
	}
	.top-banner.channel-manager-banner{
		background: $solutions-temp-top-banner-bg2;
	}
	.temp-content-holder{
		padding: 0 0 50px;
		background: $integrations-content-linear-gradient;
		.dark-box-top{
			background: $dark-box-bg;
			padding: 55px 45px;
			border-radius: 5px;
			position: relative;
			top: -120px;
			margin-bottom: -75px;
			color: $white-color;
			.title-holder{
				margin: 0 0 50px;
				h4{
					font-size: 18px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0 0 30px;
				}				
				h1{
					font-weight: 700;
					font-size: 40px;
					margin: 0;
				}
			}
			.box{
				.icon-holder{
					margin: 0 0 30px;
					img{
						max-width: 100%;
						max-height: 75px;
					}
				}
				.flx-bx{
					display: flex;
					gap: 30px;
					align-items: center;
					margin: 0 0 30px;
					.flx-icon{
						img{
							max-width: 75px;
						}
					}
					h4{
						font-size: 700;
						font-size: 16px;
						margin: 0;
					}
				}
				.flx-bx:last-child{
					margin: 0;
				}
				h4{
					font-size: 18px;
					font-weight: 700;
					margin: 0 0 30px;					
					@media(min-width: 1200px) {
						min-height: 45px;
						margin: 0 0 15px;
					}
				}
				p{
					font-size: 16px;	
					margin: 0;
					opacity: 0.7;				
				}
			}
		}
		.content-header-text{
			text-align: center;
			margin: 50px 0 75px;
			h2{
				font-size: 28px;
				font-weight: 700;
				margin: 0;
				color: $header-text-color;
				span{
					color: $header-text-color2;
				}
			}
		}
		.key-features{
			text-align: center;
			padding: 75px 0 30px;
			h1{
				font-weight: 700;
				font-size: 40px;
				margin: 0 0 10px;
			}
			p{
				font-size: 18px;
			}
		}
		.content-box{
			padding: 50px 0;
			.content{
				h1{
					margin: 0 0 30px;
					font-weight: 700;
					font-size: 36px;
				}
				p{
					font-size: 20px;
					opacity: .8;
				}
				ul{
					list-style: none;
					margin: 50px 0 0;
					padding: 0;
					li{
						display: flex;
						margin: 0 0 20px;
						.icon-holder{
							min-width: 100px;
							img{
								max-width: 65px;
							}
						}
						.li-content{
							h4{
								font-size: 16px;
								font-weight: 700;
								margin: 0 0 15px;
							}
							p{
								font-size: 16px;
								opacity: .7;
								margin: 0;
							}
						}
					}
				}
				.accordion{
					margin: 30px 0;
					.accordion-item{
						margin: 0 0 10px;
						border-radius: 0;
						border: 1px solid $default-border;
						background: $nav-toggler-bg;
						.accordion-header{
							.accordion-button{								
								font-size: 16px;
								font-weight: 700;							
								color: $black-color;
								&:focus{
									box-shadow: none;
								}
							}
							.accordion-button:not(.collapsed){
								background: $nav-toggler-bg;
								box-shadow: none;
							}
						}
						.accordion-collapse{
							color: $black-color;
							opacity: 0.7;
							font-size: 16px;
							.accordion-body{
								padding-top: 0;
							}
						}
					}
				}
				.accordion-button::after {
			    transform: rotate(-90deg);
				}
				.accordion-button:not(.collapsed)::after{
					transform: rotate(0);
    			background-image: var(--bs-accordion-btn-icon);
				}
				.try-free-btn{
					font-size: 16px;
					font-weight: 700;
					display: flex;
					align-items: center;
					margin: 0 0 30px;
					i{
						margin-left: 20px;
						font-size: 18px;
					}
				}
			}
		}
		.content-box:nth-of-type(odd) {
			.img-holder{
				img{
					position: relative;
					left: -5%;
				}
			}
		}
		.content-box:nth-of-type(even) {
			.img-holder{
				img{
					position: relative;
					right: -5%;
				}
			}
		}
		@media(min-width: 1400px){
			.dark-box-top{
				.title-holder{
					h4{
						font-size: 22px;
					}				
					h1{
						font-size: 45px;
					}
				}
				.box{
					.icon-holder{
						img{
							max-width: 100%;
							max-height: 80px;
						}
					}
					.flx-bx{
						h4{
							font-size: 18px;
						}
					}
					h4{
						font-size: 22px;
					}
					p{
						font-size: 18px;			
					}
				}
			}
			.content-header-text{
				h2{
					font-size: 31px;
				}
			}
			.key-features{
				h1{
					font-size: 45px;
				}
				p{
					font-size: 22px;
				}
			}
			.content-box{
				.content{
					h1{
						font-size: 40px;
					}
					p{
						font-size: 24px;
					}
					ul{
						li{
							margin: 0 0 30px;
							.li-content{
								h4{
									font-size: 18px;
								}
								p{
									font-size: 18px;
								}
							}
						}
					}
					.accordion{
						.accordion-item{
							.accordion-header{
								.accordion-button{								
									font-size: 18px;
								}
							}
							.accordion-collapse{
								font-size: 18px;
							}
						}
					}
					.try-free-btn{
						font-size: 18px;
						i{
							font-size: 21px;
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			.dark-box-top{
				.title-holder{				
					h1{
						font-size: 36px;
					}
				}
				.box{
					.icon-holder{
						img{
							max-height: 60px;
						}
					}
					h4{
						font-size: 18px;
					}
					p{
						font-size: 16px;			
					}
					.flx-bx{
						.flx-icon{
							img{
								max-width: 60px;
							}
						}
						h4{
							font-size: 16px;
						}
					}
				}
			}
			.content-header-text{
				margin: 30px 0 50px;
				h2{
					font-size: 24px;
				}
			}
			.key-features{
				h1{
					font-size: 36px;
				}
			}
			.content-box{
				.content{
					h1{
						font-size: 31px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.dark-box-top{
				.title-holder{				
					h1{
						font-size: 31px;
					}
				}
			}			
			.content-header-text{
				margin: 30px 0;
			}
			.key-features{
				padding: 50px 0 15px;
				h1{
					font-size: 31px;
				}
				p{
					font-size: 16px;
				}
			}
			.content-box{
				padding: 30px 0;
				.content{
					h1{
						font-size: 28px;
						margin: 0 0 15px;
					}
					p{
						font-size: 16px;
					}
					ul{
						margin: 30px 0 0;
						li{
							margin: 0 0 15px;
							.icon-holder{
								min-width: 75px;
								img{
									max-width: 50px;
								}
							}
							.li-content{
								h4{
									font-size: 16px;
								}
								p{
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
		@media(max-width: 767px){
			padding: 0;
			.dark-box-top{
				padding: 20px;
				top: -75px;
				margin-bottom: -50px;
				.title-holder{	
					margin-bottom: 0;	
					h4{
						margin: 0 0 15px;
					}		
					h1{
						font-size: 24px;
					}
				}
				.box{
					margin-top: 30px;
					.icon-holder{
						margin-bottom: 15px;
						img{
							max-height: 50px;
						}
					}
					h4{
						font-size: 16px;
						margin-bottom: 15px;
					}
					p{
						font-size: 14px;			
					}
					.flx-bx{
						gap: 15px;
						.flx-icon{
							img{
								max-width: 50px;
							}
						}
						h4{
							font-size: 14px;
						}
						&:last-child{
							margin-bottom: 30px;
						}
					}
				}
			}			
			.content-header-text{
				margin: 15px 0 50px;
				h2{
					font-size: 18px;
				}
			}
			.key-features{
				padding: 30px 0 0;
				h1{
					font-size: 24px;
				}
			}
			.content-box{
				.content{
					h1{
						font-size: 24px;
					}
					.accordion{
						.accordion-item{
							.accordion-header{
								.accordion-button{								
									font-size: 14px;
								}
							}
							.accordion-collapse{
								font-size: 14px;
								p{
									font-size: 14px;
								}
							}
						}
					}
					.try-free-btn{
						font-size: 14px;
						i{
							font-size: 16px;
						}
					}
				}
			}
			.content-box:nth-of-type(even) {
				.img-holder{
					img{
						position: relative;
						right: 0;
						left: 0;
					}
				}
			}
			.content-box:nth-of-type(odd) {
				.img-holder{
					img{
						position: relative;
						right: 0;
						left: 0;
					}
				}
			}
		}
	}
	.temp-sliders-holder{
		background: $temp-slider-bg;
		padding: 75px 0;
		color: $white-color;
		h4{
			font-size: 18px;
			font-weight: 700;
			text-transform: uppercase;
			margin: 0 0 30px;
		}				
		h1{
			font-weight: 700;
			font-size: 40px;
			margin: 0 0 30px;
		}
		p{
			font-size: 18px;
			margin: 0 0 30px;
		}
		.btn-browse-temp{
			width: 260px;
			height: 60px;
			background: transparent;
			border-radius: 5px;
			color: $white-color;
			font-weight: 700;
			font-size: 18px;
			padding: 0 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid $white-color;
			background-position: center;
				transition: background 0.8s;
		}
    .btn-browse-temp:hover {
      background: $white-color;
      color: $anchor-color;
    }
    .template-sliders{
			margin: 75px 0 0;
			.carousel-indicators{
				margin-bottom: -15px;
			}
			.carousel-indicators [data-bs-target]{
				background-color: $white-color;
				width: 18px;
			}
		}
		@media(min-width: 1400px){
			h4{
				font-size: 22px;
			}				
			h1{
				font-size: 45px;
			}
			p{
				font-size: 22px;
			}
			.btn-browse-temp{
				width: 280px;
				height: 70px;
				font-size: 22px;
			}
		}
		@media(max-width: 1199px){	
			padding: 50px 0;		
			h1{
				font-size: 36px;
			}
			.btn-browse-temp{
				font-size: 16px;
			}
			.template-sliders{
				margin: 50px 0 0;
			}
		}
		@media(max-width: 991px){			
			h1{
				font-size: 31px;
			}
			h4, p{
				font-size: 16px;
			}
			.btn-browse-temp{
				width: 230px;
			}
			.template-sliders{
				margin: 50px 0 0;
			}
		}
		@media(max-width: 767px){		
			padding: 30px 0 30px;	
			h1{
				font-size: 24px;
				margin: 0 0 15px;
			}
			h4, p{
				font-size: 16px;
				margin: 0 0 15px;
			}
			.btn-browse-temp{
				width: 230px;
				height: 50px;
				margin-top: 30px;
			}
			.template-sliders{
				margin: 30px 0 0;
			}
		}
	}
	.create-free-website{
		padding: 100px 0;
		background: $white-color;
		.title-holder{
			h4{
				font-size: 18px;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0 0 30px;
				color: $anchor-color;
			}				
			h1{
				font-weight: 700;
				font-size: 40px;
				margin: 0;
			}
		}
		.flex-content-box{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 50px;
			margin: 75px 0 50px;
			.box{
				width: 28%;
				display: flex;
				gap: 20px;
				.img-holder{
					img{
						max-width: 65px;
					}
				}
				.content-holder{
					h4{
						font-size: 18px;
						font-weight: 700;
						margin: 0 0 20px;
					}
					p{
						font-size: 16px;
					}
				}
			}
		}
		.btn-holder{
			display: flex;
			justify-content: center;
			.btn{
	        width: 260px;
	        height: 60px;
	        background: $black-color;
	        border-radius: 5px;
	        color: $white-color;
	        font-weight: 700;
	        font-size: 18px;
	        padding: 0 30px;
	        display: flex;
	        justify-content: space-between;
	        align-items: center;
	        background-position: center;
	        border: 1px solid $black-color;
	        transition: background 0.8s;
	    }
	    .btn:hover {
	      background: $ripple-btn-dflt-hvr;
	      color: $black-color;
	    }
	    .btn:active {
	      background-color: transparent;
	      background-size: 100%;
	      transition: background 0s;
	    }
		}
		@media(min-width: 1400px){
			.flx-log-box{
				gap: 30px;
			}
			.title-holder{
				h4{
					font-size: 24px;
				}
				h1{
					font-size: 45px;
				}
			}
			.flex-content-box{
				.box{
					gap: 30px;
					.content-holder{
						h4{
							font-size: 24px;
						}
						p{
							font-size: 18px;
						}
					}
				}
			}
			.btn-holder{
				.btn{
            width: 280px;
            height: 70px;
            font-size: 22px;
        }
			}
		}
		@media(max-width: 1199px){
			padding: 75px 0;
			.flx-log-box{
				gap: 30px;
				padding: 0 0 50px;
			}
			.title-holder{
				h4{
					font-size: 16px;
					margin: 0 0 15px;
				}
				h1{
					font-size: 31px;
				}
			}
			.flex-content-box{
				gap: 30px;
				margin: 50px 0;
				.box{
					gap: 15px;
					.img-holder{
						img{
							max-width: 50px;
						}
					}
				}
			}
			.btn-holder{
				.btn{
            width: 250px;
            font-size: 16px;
        }
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			.flx-log-box{
				gap: 15px;
			}
			.flex-content-box{
				gap: 30px;
				margin: 50px 0;
				.box{
					width: 45%;
					gap: 15px;
				}
			}
		}
		@media(max-width: 767px){
			padding: 30px 0 50px;
			.flx-log-box{
				gap: 15px;
				padding: 0 0 30px;
				flex-wrap: wrap;
				justify-content: center;
				.logo-box{
					max-width: 29%;
				}
			}
			.title-holder{
				h4{
					margin: 0 0 10px;
				}
				h1{
					font-size: 24px;
				}
			}
			.btn-holder{
				.btn{
            height: 50px;
        }
			}
		}
		@media(max-width: 574px){
			.flex-content-box{
				margin: 30px 0;
				.box{
					width: 100%;
					.content-holder{
						h4{
							margin: 0 0 5px;
						}
						p{
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	.covid-resources-holder{
		padding: 0 0 75px;
		background: $white-color;
		.covid-resources{
			background-image: url('../images/solutions/covid-bg-left.png'), url('../images/solutions/covid-bg-right.png');
		  background-position: left bottom, right center;
		  background-repeat: no-repeat, no-repeat;
		  background-size: auto 100%, auto 70%;
		  background-color: $covid-resources-bg;
			border-radius: 5px;
			padding: 15px 15px 0;
			position: relative;
			top: -80px;
			margin-bottom: -30px;
			color: $white-color;
		  .title-holder{
		  	padding: 30px 45px 45px;
		  	background-image: url('../images/solutions/women-with-mask.png'), url('../images/solutions/men-with-mask.png');
			  background-position: left bottom, right top;
			  background-repeat: no-repeat, no-repeat;
			  background-size: auto 100%, auto 100%;
			  text-align: center;
			  h4{
					font-size: 18px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0 0 10px;
				}				
				h1{
					font-weight: 700;
					font-size: 31px;
					margin: 0;
				}
		  }
		}
		.content-box-holder{
			.content-box{
				border-radius: 10px;
				height: 100%;
				padding: 50px 50px 25px;
				.img-holder{
					margin-bottom: 30px;
					img{
						max-width: 200px;
					}
				}
				h2{
					font-weight: 700;
					font-size: 31px;
					margin: 0 0 30px;
				}
				ul{
					list-style: none;
					padding: 0;
					margin: 0;
					li{
						font-size: 16px;
						padding: 0 0 0 35px;
						margin: 0 0 25px;
					}
				}
			}
			.content-box.problem{
				background: $light-pink;
				ul{
					li{
						background: url('../images/solutions/sad.png') no-repeat left top 4px;
						background-size: 20px;
					}
				}
			}
			.content-box.solution{
				background: $light-gray;
				ul{
					li{
						background: url('../images/solutions/smile.png') no-repeat left top 4px;
						background-size: 20px;
					}
				}
			}
		}
		@media(min-width: 1400px){
			.covid-resources{
			  .title-holder{
				  h4{
						font-size: 22px;
					}				
					h1{
						font-size: 36px;
					}
			  }
			}
			.content-box-holder{
				.content-box{
					h2{
						font-size: 36px;
					}
					ul{
						li{
							padding: 0 0 0 40px;
							font-size: 18px;
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			.covid-resources{
			  .title-holder{			
					h1{
						font-size: 28px;
					}
			  }
			}
			.content-box-holder{
				.content-box{
					h2{
						font-size: 28px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.covid-resources{
			  .title-holder{			
					h1{
						font-size: 24px;
					}
			  }
			}
			.content-box-holder{
				.content-box{
					padding: 30px 30px 15px;
					h2{
						font-size: 24px;
					}
				}
			}
		}
		@media(max-width: 767px){
			padding: 0 0 30px;
			.covid-resources{
				margin-bottom: -50px;
			  .title-holder{			
					h4{
						font-size: 16px;
					}
			  }
			}
			.content-box-holder{
				margin-bottom: 15px;
			}
		}
	}
	.what-we-offer{
		background: $services-holder-bg;
		padding: 75px 0;
		.title-holder{
			margin: 0 0 30px;
			h4{
				font-size: 18px;
				font-weight: 700;
				margin: 0 0 30px;
				color: $anchor-color;
				text-transform: uppercase;
			}
			h1{
				font-weight: 700;
				font-size: 36px;
				margin: 0;
			}
		}
		.flex-content-box{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 50px;
			margin: 50px 0 0;
			.box{
				width: 30%;
				display: flex;
				gap: 30px;
				.img-holder{
					img{
						max-width: 50px;
					}
				}
				.content-holder{
					h4{
						font-size: 18px;
						font-weight: 700;
						margin: 0 0 20px;
					}
					p{
						font-size: 16px;
					}
					.btn-holder{
						margin: 20px 0 0;
						a{
							font-size: 16px;
							font-weight: 700;
							display: flex;
	    					align-items: center;
							i{
								margin-left: 20px;
								font-size: 18px;
							}
						}
					}
				}
			}
		}
		@media(min-width: 1400px){
			.title-holder{
				h4{
					font-size: 24px;
				}
				h1{
					font-size: 45px;
				}
			}
			.flex-content-box{
				.box{
					.content-holder{
						h4{
							font-size: 24px;
						}
						p{
							font-size: 18px;
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			.title-holder{
				h4{
					font-size: 16px;
					margin: 0 0 15px;
				}
				h1{
					font-size: 31px;
				}
			}
			.flex-content-box{
				gap: 30px;
				.box{
					gap: 15px;
				}
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			.flex-content-box{
				gap: 30px;
				.box{
					width: 45%;
					gap: 15px;
				}
			}
		}
		@media(max-width: 767px){
			padding: 30px 0;
			.title-holder{
				h4{
					margin: 0 0 10px;
				}
				h1{
					font-size: 24px;
				}
			}
		}
		@media(max-width: 574px){
			.flex-content-box{
				.box{
					width: 100%;
					.content-holder{
						h4{
							margin: 0 0 5px;
						}
						p{
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	.how-it-works{
		padding: 75px 0;
		background: url('../images/solutions/how-it-works-bg.jpg') no-repeat left bottom $hiw-bg;
		background-attachment: fixed;
		background-size: cover;
		color: $white-color;
		text-align: center;
		h1{
			font-weight: 700;
			font-size: 40px;
			margin: 0 0 50px;
		}
		.tab-holder{
			.nav-tabs{
		    display: inline-flex;
			  border: 1px solid $white-color;
			  border-radius: 25px;
			  padding: 5px;
			  margin: 0 0 50px;
			  .nav-link{
			  	border: 0;
			  	color: $white-color;
			  	font-weight: 700;
			  	font-size: 16px;			  	
			  	border-radius: 25px;
			  	height: 40px;
			  	padding: 5px 25px;
			  	&:hover{
			  		border: 0;
			  	}
			  }
			  .nav-link.active{
			  	background: $nav-tab-link-active-hiw;
			  	border-radius: 25px;
			  }
			}
			.tab-content{
				.img-holder{
					margin: 0 0 75px;
					img{
						max-width: 100%;
					}
				}
				.btn-holder{
					display: flex;
					justify-content: center;
					.btn{
	          width: 260px;
	          height: 60px;
	          background: $black-color;
	          border-radius: 5px;
	          color: $white-color;
	          font-weight: 700;
	          font-size: 18px;
	          padding: 0 30px;
	          display: flex;
	          justify-content: space-between;
	          align-items: center;
	          background-position: center;
	          border: 1px solid $black-color;
	          transition: background 0.8s;
          }
          .btn:hover {
            background: transparent radial-gradient(circle, transparent 1%, rgba(255,255,255,.5) 1%) center/15000%;
            color: $white-color;
            border: 1px solid $white-color;
          }
          .btn:active {
            background-color: transparent;
            background-size: 100%;
            transition: background 0s;
          }
				}
			}
		}

		
		@media(min-width: 1400px){
			h1{
				font-size: 45px;
			}
			.tab-holder{
				.nav-tabs{
				  .nav-link{
				  	font-size: 18px;	
				  }
				}
				.tab-content{
					.btn-holder{
						.btn{
		          width: 280px;
              height: 70px;
              font-size: 22px;
	          }
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 50px 0;
			h1{
				font-size: 31px;
			}
			.tab-holder{
				.tab-content{
					.img-holder{
						margin: 0 0 50px;
					}
					.btn-holder{
						.btn{
		          width: 250px;
		          font-size: 16px;
	          }
					}
				}
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			h1{
				font-size: 28px;
				margin: 0 0 30px;
			}
			.tab-holder{
				.nav-tabs{
				  margin: 0 0 30px;
				}
				.tab-content{
					.img-holder{
						margin: 0 0 30px;
					}
				}
			}
		}
		@media(max-width: 767px){
			h1{
				font-size: 24px;
			}
			.tab-holder{
				.nav-tabs{
				  .nav-link{
				  	font-size: 14px;	
				  	padding: 5px 15px;
				  	height: 35px;
				  }
				}
				.tab-content{
					.btn-holder{
						.btn{
		          height: 50px;
		          width: 220px;
	          }
					}
				}
			}
		}
		@media(max-width: 574px){
			padding: 30px 0;
		}
	}
	.stay-connected{
		padding: 100px 0;
		background: $white-color;
		h4{
			font-size: 18px;
			color: $anchor-color;
			font-weight: 700;
			margin: 0 0 30px;
		}
		h1{
			font-size: 40px;
			font-weight: 700;
			margin: 0 0 30px;
		}
		p{
			font-size: 18px;
			margin: 0 0 50px;
		}
		.flx-box-holder{
			margin: 0 0 15px;
			.flx-box{
				display: flex;
				align-items: center;
				box-shadow: 0 0 30px $property_box-shadow;
				align-items: stretch;
				.icon-holder{
					display: flex;
					align-items: center;
					justify-content: center;
					min-width: 100px;
					img{
						height: 40px;
					}
				}
				.icon-holder.property_managers{
					background: $property_managers-bg;
				}
				.icon-holder.service_providers{
					background: $service_providers-bg;
				}
				.cnt-holder{
					padding: 20px;
					h5{
						font-size: 16px;
						font-weight: 700;
						margin: 0 0 5px;
					}
					p{
						font-size: 14px;
						margin: 0 0 15px;
						opacity: .7;
					}
					.btn-holder{
						display: flex;
						justify-content: space-between;
						gap: 20px;
						a{							
							img{
								max-width: 80%;
							}
							&:hover{
								img{
									opacity: .8;
								}
							}
						}
					}
				}
			}
		}
		@media(min-width: 1400px){
			h4{
				font-size: 22px;
			}
			h1{
				font-size: 45px;
			}
			p{
				font-size: 22px;
			}
			.flx-box-holder{
				.flx-box{
					.cnt-holder{
						h5{
							font-size: 18px;
						}
						p{
							font-size: 16px;
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 75px 0;
			h1{
				font-size: 36px;
			}
			.flx-box-holder{
				.flx-box{
					.cnt-holder{
						.btn-holder{
							a{							
								img{
									max-width: 100%;
								}
							}
						}
					}
				}
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			h4{
				font-size: 16px;
				margin: 0 0 15px;
			}
			h1{
				font-size: 31px;
				margin: 0 0 15px;
			}
			p{
				font-size: 16px;
				margin: 0 0 30px;
			}
		}
		@media(max-width: 767px){
			padding: 30px 0;
			h1{
				font-size: 24px;
			}
		}
	}
}
