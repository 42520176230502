@import "variables";
.casestudies-layout{
	.top-banner{
		background: $integrations-top-banner-linear-gradient;
		padding: 100px 0 300px;
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 42px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
			}
		}
		@media(max-width: 1199px){
			padding: 75px 0 300px;
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
			}
		}
		@media(max-width: 991px){
			padding: 50px 0 270px;
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 93px;
			.banner-caption{
				text-align: center;
				margin: 0 0 30px;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
			}
		}
	}
	.casestudies-content-holder{
		background: $integrations-content-linear-gradient;
		padding: 0 0 100px;
		.banner-holder{
			position: relative;
			top: -310px;
			margin-bottom: -350px;
			img{
				width: 105%;
				height: auto;
				left: -2.5%;
				position: relative;
			}
		}
		.content-box{
			margin-bottom: 30px;
			.box{
				background: $white-color;
				border-radius: 4px;
				padding: 30px;
				height: 100%;
				.img-holder{
					display: flex;
					align-items: center;
					justify-content: center;
					height: 220px;
					margin-bottom: 20px;
					img{
						max-width: 100%;
						max-height: 100%;
					}
				}
				h4{
					font-weight: 700;
					font-size: 16px;
					margin: 0 0 20px;
				}
				p{
					font-size: 16px;
					margin: 0 0 20px;
				}
				.auth{
					font-size: 12px;
					opacity: .5;					
					margin: 0 0 30px;
				}
				.btn-full-case-story{
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					i{
						margin-left: 20px;
					}
				}
			}
		}
		@media(min-width: 1400px){
			.content-box{
				.box{
					h4{
						font-size: 18px;
						margin: 0 0 30px;
					}
					p{
						font-size: 18px;
						margin: 0 0 30px;
					}
					.auth{
						font-size: 14px;
					}
					.btn-full-case-story{
						font-size: 16px;
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 0 0 75px;
			.banner-holder{
				position: relative;
				top: -310px;
				margin-bottom: -350px;
				img{
					width: 100%;
					height: auto;
					left: 0%;
					position: relative;
				}
			}
		}		
		@media(max-width: 767px){
			padding: 0 0 30px;
			.banner-holder{
				position: relative;
				top: -100px;
				margin-bottom: -110px;
			}
			.content-box {
				.box{
					padding: 20px;
					.img-holder{
						height: auto;
						margin-bottom: 20px;
					}
					h4{
						margin: 0 0 15px;
					}
					p{
						margin: 0 0 15px;
					}
					.auth{				
						margin: 0 0 20px;
					}
					.btn-full-case-story{
						i{
							margin-left: 15px;
						}
					}
				}
			}
		}
	}
	.top-banner.text-base-banner{
		padding-bottom: 200px;
	}
	.top-banner.city_concierge{
		padding: 50px 0 120px; 
		h4{
			color: $anchor-color;
			font-weight: 700;
			font-size: 18px;
			text-transform: uppercase;
			margin: 0 0 30px;
		}
		@media(min-width: 1400){
			h4{
				font-size: 21px;
			}
		}
		@media(max-width: 767px){
			padding: 30px 0 120px;
			h4{
				margin: 0 0 15px;
			}
			h1{
				margin: 0;
			}
		}
	}
	.casestudies-full-content-holder{
		background: $white-color;
		.detail-box-holder{
			background: $terms-container-bg;
			padding: 40px;
			position: relative;
			top: -200px;
			margin-bottom: -160px;
			.detail-box{
				background: $white-color;
				padding: 50px;
				.highlighted-green-box{
					background-image: url('../images/case-studies/left-graphics.png'), url('../images/case-studies/right-graphics.png');
					background-color: $case-study-green-bg;
					background-repeat: no-repeat, no-repeat;
					background-position: left bottom, right top;
					background-size: auto 90%, auto 60%;
					padding: 30px 50px;
					text-align: center;
					font-weight: 700;
					font-size: 18px;
					margin-bottom: 30px;
					min-height: 160px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				h2{
					font-weight: 700;
					font-size: 36px;
					margin: 50px 0 30px;
				}
				p{
					font-size: 18px;
					margin: 0 0 30px;
				}
				.light-box{
					background: $case-study-light-bg;
					border: 1px solid $default-border;
					text-align: center;
					padding: 30px;
					margin: 0 0 50px;
					img{
						max-width: 100%;
						max-height: 45px;
					}
				}
			}
		}
		.learn-more-about-business{
			background: url('../images/case-studies/bg_lear_more_about_business.png') no-repeat right bottom;
			background-size: cover;
			padding: 75px 50px;
			color: $white-color;
			font-weight: 700;
			font-size: 36px;
			margin-bottom: 50px;
		}
		@media(min-width: 1400px){
			.detail-box-holder{
				.detail-box{
					.highlighted-green-box{
						font-size: 22px;
					}
					h2{
						font-size: 40px;
					}
					p{
						font-size: 22px;
					}
					.light-box{
						img{
							max-height: 50px;
						}
					}
				}
			}
			.learn-more-about-business{
				font-size: 40px;
			}
		}
		@media(max-width: 1200px){
			.detail-box-holder{
				.detail-box{
					h2{
						font-size: 31px;
					}
				}
			}
			.learn-more-about-business{
				font-size: 31px;
			}
		}
		@media(max-width: 991px){
			.detail-box-holder{
				padding: 30px;
				.detail-box{
					padding: 30px;
					.highlighted-green-box{
						font-size: 16px;
					}
					h2{
						font-size: 24px;
					}
					p{
						font-size: 16px;
					}
				}
			}
			.learn-more-about-business{
				padding: 50px;
				font-size: 24px;
			}
		}
		@media(max-width: 767px){
			.detail-box-holder{
				padding: 15px;
				margin-bottom: -180px;
				.detail-box{
					padding: 15px;
					h2{
						font-size: 18px;
						margin: 30px 0 15px;
					}
					.highlighted-green-box {
						font-size: 16px;
						padding: 15px;
						margin: 0 0 15px;
					}
					.light-box{
						padding: 15px;
						margin: 0 0 30px;
						img{
							max-height: 25px;
						}
					}
				}
			}
			.learn-more-about-business{
				padding: 30px;
				font-size: 18px;
				text-align: center;
				margin: 0 0 30px;
				background-position: left;
			}
		}
	}
	.overview-holder{
		padding: 0 0 100px;
		background: $white-color;
		.overview-dark-box{
			background: $dark-box-bg;
			padding: 55px 45px;
			border-radius: 5px;
			position: relative;
			top: -120px;
			margin-bottom: -50px;
			color: $white-color;
			.box-left{
				text-align: center;
				border-right: 1px solid rgba($color: $white-color, $alpha: .3);
				padding-right: 45px;
				p{
					font-size: 24px;
					margin: 0 0 30px;
				}
				.auth-flx-box{
					display: inline-flex;
					align-items: center;
					gap: 10px;
					text-align: left;
					img{
						border-radius: 50%;
						width: 50px;
						height: 50px;
					}
					h5{
						font-weight: 700;
						font-size: 18px;
						margin: 0 0 5px;
					}
					p{
						font-size: 14px;
						margin: 0;
					}
				}
			}
			.box-right{
				padding-left: 45px;
				h4{
					text-transform: uppercase;
					font-size: 18px;
					font-weight: 700;
					margin: 0 0 30px;
				}
				img{
					max-width: 100%;
					max-height: 75px;
					margin: 0 0 20px;
				}
				p{
					font-size: 16px;
					opacity: .8;
					margin: 0 0 20px;
				}
				a{
					font-size: 18px;
					font-weight: 700;
				}
			}
		}
		.overview-content-box{
			h4{
				color: $anchor-color;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0 0 30px;
				text-align: center;
				font-size: 18px;
			}
			h1{
				font-weight: 700;
				text-align: center;
				font-size: 36px;
				margin: 0 0 50px;
			}
			p{
				font-size: 18px;
			}
			.box-left{
				border-right: 1px solid rgba($color: $black-color, $alpha: .1);
				padding-right: 45px;
			}
			.box-right{
				padding-left: 45px;
			}				
			.img-holder{
				margin: 30px 0 0;
				img{
					margin-left: -3.5%;
				}
			}
		}	
	}
	.performance-holder{
		background: $performance-box-bg;
		padding: 100px 0;
		.title-holder{	
			margin: 0 0 75px;
			text-align: center;		
			h4{
				font-size: 18px;
				text-transform: uppercase;
				color: $anchor-color;
				margin: 0 0 15px;
				font-weight: 700;
			}
			h1{
				font-size: 40px;
				font-weight: 700;
				margin: 0;
			}
		}		
		.performance-content-box{
			.content-box-l{
				padding: 0 30px 30px 15px;
			}
			.content-box-r{
				padding: 0 15px 30px 30px;
			}
			p{
				font-size: 16px;
			}
			img{
				max-width: 100%;
				margin-bottom: 30px;
			}
			ul{
				margin: 0;
				list-style: none;
				padding: 0;
				counter-reset: step;	
				font-size: 16px;			
				li{
					margin: 0 0 15px;
					position: relative;
					padding: 0 0 0 40px;
					min-height: 30px;
				}
				li:before{
					content: counter(step);
					counter-increment: step 1;
					position: absolute;
					left: 0;
					top: 2px;
					background: $step-bg-gray;
					width: 25px;
					height: 25px;
					border-radius: 50%;
					text-align: center;
					line-height: 25px;
					font-size: 14px;
				}
			}
		}

	}
	.moving-forward{
		padding: 100px 0 50px;
		background: $white-color;
		h1{
			font-size: 40px;
			font-weight: 700;
			margin: 0 0 30px;
		}
		p{
			font-size: 16px;
			margin: 0 0 30px;
		}
		.learn-more-about-business{
			background: url('../images/case-studies/bg_lear_more_about_business.png') no-repeat right bottom;
			background-size: cover;
			padding: 75px 50px;
			color: $white-color;
			font-weight: 700;
			font-size: 36px;
			margin: 75px 0 50px;
			@media(min-width: 1400px){
				font-size: 40px;
			}
			@media(max-width: 1199px){
				.learn-more-about-business{
					font-size: 31px;
				}
			}
			@media(max-width: 991px){
				padding: 50px;
				font-size: 24px;
				margin: 30px 0;
			}
			@media(max-width: 767px){
				padding: 30px;
				font-size: 18px;
				text-align: center;
				background-position: left;
			}
		}
	}
	@media(min-width: 1400px){
		.overview-holder{
			.overview-dark-box{
				.box-left{
					p{
						font-size: 28px;
					}
					.auth-flx-box{
						img{
							width: 60px;
							height: 60px;
						}
						h5{
							font-size: 22px;
						}
						p{
							font-size: 16px;
						}
					}
				}
				.box-right{
					h4{
						font-size: 22px;
					}
					p{
						font-size: 18px;
					}
					a{
						font-size: 22px;
					}
				}
			}
			.overview-content-box{
				h4{
					font-size: 22px;
				}
				h1{
					font-size: 40px;
				}
				p{
					font-size: 22px;
				}
			}	
		}
		.performance-holder{
			.title-holder{			
				h4{
					font-size: 22px;
				}
				h1{
					font-size: 45px;
				}
			}		
			.performance-content-box{
				p{
					font-size: 18px;
				}
				ul{
					font-size: 18px;
					li:before{
						font-size: 16px;
					}
				}
			}
	
		}
		.moving-forward{
			h1{
				font-size: 45px;
			}
			p{
				font-size: 18px;
			}
		}
	}
	@media(max-width: 1199px){
		.overview-holder{
			padding: 0 0 75px;
		}
		.performance-holder{
			padding: 75px 0;
			.title-holder{	
				margin: 0 0 50px;
				h1{
					font-size: 36px;
				}
			}	
	
		}
		.moving-forward{
			padding: 75px 0 30px;
			h1{
				font-size: 36px;
			}
		}
	}
	@media(max-width: 991px){
		.overview-holder{
			padding: 0 0 50px;
			.overview-dark-box{
				padding: 30px;
				top: -120px;
				margin-bottom: -50px;
				.box-left{
					padding-right: 30px;
					p{
						font-size: 18px;
					}
					.auth-flx-box{
						h5{
							font-size: 16px;
						}
						p{
							font-size: 12px;
						}
					}
				}
				.box-right{
					padding-left: 30px;
					h4{
						font-size: 16px;
					}
					p{
						font-size: 14px;
					}
					a{
						font-size: 16px;
					}
				}
			}
			.overview-content-box{
				h4{
					font-size: 16px;
				}
				h1{
					font-size: 31px;
					margin: 0 0 30px;
				}
				p{
					font-size: 16px;
				}
				.box-left{
					padding-right: 30px;
				}
				.box-right{
					padding-left: 30px;
				}
			}	
		}
		.performance-holder{
			padding: 50px 0;
			.title-holder{	
				h1{
					font-size: 31px;
				}
			}		
	
		}
		.moving-forward{
			padding: 50px 0 30px;
			background: $white-color;
			h1{
				font-size: 31px;
			}
		}
	}
	@media(max-width: 767px){
		.overview-holder{
			padding: 0 0 30px;
			.overview-dark-box{
				padding: 30px 20px;
				margin-bottom: -80px;
				.box-left{
					padding-right: 15px;
					border-right: 0;
					border-bottom: 1px solid rgba($color: $white-color, $alpha: .3);
					padding-bottom: 30px;
					p{
						font-size: 16px;
					}
					.auth-flx-box{
						h5{
							font-size: 14px;
						}
						p{
							font-size: 12px;
						}
					}
				}
				.box-right{
					padding-left: 15px;
					padding-top: 30px;
					text-align: center;
					h4{
						font-size: 16px;
						margin: 0 0 15px;
					}
					p{
						font-size: 14px;
						margin: 0 0 15px;
					}
					a{
						font-size: 16px;
					}
				}
			}
			.overview-content-box{
				text-align: center;
				h4{
					margin: 0 0 15px;
				}
				h1{
					font-size: 24px;
					margin: 0 0 15px;
				}
				.box-left{
					padding-right: 15px;
				}
				.box-right{
					padding-left: 15px;
				}
				.img-holder{
					margin-top: 15px;
					img {
						margin-left: 0;
					}
				} 
			}	
		}
		.performance-holder{
			padding: 30px 0;
			.title-holder{					
				text-align: center;
				margin: 0 0 15px;
				h4{
					margin: 0 0 10px;
				}
				h1{
					font-size: 24px;
				}
			}		
			.performance-content-box{
				.content-box-l{
					padding: 15px;
				}
				.content-box-r{
					padding: 15px;
				}
			}
		}
		.moving-forward{
			padding: 30px 0 0;
			text-align: center;
			h1{
				font-size: 24px;
				margin: 0 0 15px;
			}
		}
	}
}
