@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import './assets/scss/variables';
@import './assets/scss/typography';
.loading-screen, #loading-screen{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $nav-toggler-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    opacity: 1;
    visibility: visible;
    transition: all 1s;
    z-index: 1;
}