// ===Fonts===
$default-font: 'Lato', sans-serif;

// =========All Colors=========
$default-bg: #ffffff;
$default-color: #000000;
$secondary-color: #777777;
$default-scroll-bar-color: #ccc;
$default-scroll-bar-hvr-color: #7f7f7f;
$default-border: #e2e2e2;
$anchor-color: #1AA99D;
$anchor-color2: #1fbdd3;
$anchor-hvr-color: #5FB963;
$black-border-half-opacity: rgba(0, 0, 0, 0.5);
$black-border-opacity: rgba(0, 0, 0, 0.3);
$white-border-opacity: rgba(255, 255, 255, 0.3);

$navbar-bg: #ffffff;
$nav-color: #000000;
$nav-hvr-color: #1AA99D;
$nav-toggler-bg: #F8F8F8;
$dropdown-menu-shadow: #8c97b14d;
$dropdown-menu-hover: #EDF8F8;
$dropdown-menu-icon-bg: #EFEFEF;
$try-for-free-btn-hvr: #1AA99D radial-gradient(circle, transparent 1%, #1AA99D 1%) center/15000%;
$nav-dark-bg: #23252D;
$nav-dark-shadow: #BEC1CC29;
$nav-dark-btn-bg: #5CBC63;

$white-color: #ffffff;
$black-color: #000000;
$light-pink: #FFEBEB;
$light-gray: #F3F3FF;
$gray: #8c8f90;
$ripple-btn-dflt-hvr: transparent radial-gradient(circle, transparent 1%, #ffffff 1%) center/15000%;

// footer
$ftr-top-need-help-bg: linear-gradient(106deg, hsla(189, 100%, 93%, 1) 0%, hsla(29, 100%, 96%, 1) 50%, hsla(155, 66%, 87%, 1) 100%);

// Homepage
$homepage-top-banner-linear-gradient: linear-gradient(to right, #F6F4FF 0%, 21%, #C3E5FF 42%, 71%, #E8FFFA 100%);
$channel-holder-bg: linear-gradient(116deg, rgba(252, 248, 239, .5) 0%, rgba(223, 240, 247, .5) 25%, rgba(247, 240, 255, .5) 50%, rgba(230, 255, 248, .5) 74%, #E6FFF8 100%);
$product-holder-bg: linear-gradient(90deg, hsla(147, 38%, 31%, 1) 0%, hsla(186, 69%, 34%, 1) 100%, hsla(37, 89%, 52%, 1) 100%);
$services-holder-bg: linear-gradient(123deg, hsla(42, 68%, 96%, .5) 0%, hsla(198, 60%, 92%, .5) 33%, hsla(268, 100%, 97%, .5) 66%, hsla(163, 100%, 95%, .5) 100%);
$indicators-gray: #bebebe;

// Pricing Page
$pricing-top-banner-linear-gradient: transparent linear-gradient(105deg, #EEEBFF 0%, #C2EAF8 43%, #E5F8D4 71%, #FFF8EA 100%);
$range-slider-bg: #444652;
$range-slider-thumb: #17A52F;
$rental-selection-box-bg: #23252D;
$rental-selection-box-shadow: #BEC1CC29;
$step-bg: #191D26;
$step-color: #77A265;
$radio-box-bg: #3C4048;
$radio-box-checked: #17A52F;
$free-trail-label: #DEDEDE;
$book-demo-btn-bg: #FF8B36;

// Integrations Page
$integrations-top-banner-linear-gradient: transparent linear-gradient(108deg, #EEEBFF 0%, #C2EAF8 43%, #E5F8D4 71%, #FFF8EA 100%);
$integrations-content-linear-gradient: transparent linear-gradient(165deg, rgba(252,248,239,.5) 0%, rgba(223,240,247,.5) 36%, rgba(247,240,255,.5) 74%, rgba(230,255,248,.5) 100%);

// About Us Page
$about-top-banner-bg: transparent linear-gradient(111deg, #EEEBFF 0%, #C2EAF8 43%, #E5F8D4 71%, #FFF8EA 100%);
$dark-box-bg: transparent linear-gradient(113deg, #2A2F39 0%, #22242B 100%);

// Solutions Pages
$solutions-temp-top-banner-bg: transparent linear-gradient(111deg, #EEEBFF 0%, #C2EAF8 43%, #E5F8D4 71%, #FFF8EA 100%);
$solutions-temp-top-banner-bg2: transparent linear-gradient(111deg, #EFF5F4 0%, #E7FDFC 43%, #B3ECFD 71%, #DED5FA 100%);
$header-text-color: #767C89;
$header-text-color2: #23262D;
$temp-slider-bg: transparent linear-gradient(123deg, #316D4A 0%, #158BA3 100%);
$covid-resources-bg: #23262d;
$property_box-shadow: #e5e5e5;
$property_managers-bg: #55B931;
$service_providers-bg: #3AC796;
$hiw-bg: #258a94;
$nav-tab-link-active-hiw: #42B716;

$terms-bg: transparent linear-gradient(109deg, #EEEBFF 0%, #C2EAF8 43%, #E5F8D4 71%, #FFF8EA 100%);
$terms-container-bg: transparent linear-gradient(155deg, #FCF8EF 0%, #DFF0F7 36%, #F7F0FF 74%, #E6FFF8 100%);

$affiliate-container-bg: transparent linear-gradient(146deg, #FCF8EF 0%, #DFF0F7 36%, #F7F0FF 74%, #E6FFF8 100%);
$qstn-bg-bg: #e5e5e5;
$start-earning-bg: transparent linear-gradient(105deg, #316D4A 0%, #158BA3 100%);
$looking-more-customer-bg: #e3f4f3;
$case-study-light-bg: #F7F7F7;
$case-study-green-bg: #def0ef;
$performance-box-bg: transparent linear-gradient(121deg, #FCF8EF 0%, #DFF0F7 36%, #F7F0FF 74%, #E6FFF8 100%);
$step-bg-gray: #cccccc;
$need-help-box-bg: #F4F4F4;
$notice-bar-bg: #1EB1C6;