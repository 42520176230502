@import "variables";
.reviews-page{
	.top-banner{
		background: $integrations-top-banner-linear-gradient;
		padding: 50px 0;
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 42px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
			}
		}
		@media(max-width: 1199px){
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
			}
		}
		@media(max-width: 991px){
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 50px;
			.banner-caption{
				text-align: center;
				margin: 30px 0;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
			}
		}
	}
	.reviews-holder{
		background: $white-color;
		padding: 0 0 75px;
		.gGBTqe {
			max-width: 100%;
		}
		.hwcrJd{
			color: $white-color;
			background: $dark-box-bg;
			padding: 30px 50px;
			position: relative;
			top: -55px;
			margin-bottom: -15px;
		}
		.bcmMyG, .ffvtag{
			color: $white-color;
		}
		.cPcdzQ{
			background: $anchor-color !important;
		}
		.iBePBU{
			font-size: 16px;
		}
	}
	.looking-more-customer{
		background: url('../images/customer-experience-bg.png') no-repeat right top $looking-more-customer-bg;
		background-size: contain;
		background-attachment: fixed;
		padding: 100px 0 75px;
		text-align: center;
		h1{
			font-size: 40px;
			font-weight: 700;
			margin: 0 0 20px;
		}
		p{
			font-size: 18px;
		}
		.img-holder{
			margin-top: 45px;
			a{
				display: inline-block;
				margin: 10px;
				img{
					max-width: 100%;
					max-height: 80px;
				}
				&:hover{
					opacity: .8;
				}
			}
		}
	}
	.we-want-hear-from-you{
		background: $integrations-content-linear-gradient;
		padding: 100px 0;
		h1{
			font-weight: 700;
			font-size: 31px;
			margin: 0 0 30px;
		}
		p{
			font-size: 18px;
			margin: 0;
		}
	}
	@media(min-width: 1400px){
		.looking-more-customer{
			h1{
				font-size: 45px;
				margin: 0 0 30px;
			}
			p{
				font-size: 22px;
			}
			.img-holder{
				margin-top: 50px;
			}
		}
		.we-want-hear-from-you{
			h1{
				font-size: 36px;
			}
			p{
				font-size: 22px;
			}
		}
	}
	@media(max-width: 1199px){
		.looking-more-customer{
			padding: 75px 0;
			h1{
				font-size: 36px;
			}
		}
		.we-want-hear-from-you{
			padding: 75px 0;
		}
	}
	@media(max-width: 991px){
		.looking-more-customer{
			padding: 50px 0;
			h1{
				font-size: 31px;
			}
		}
		.we-want-hear-from-you{
			padding: 50px 0;
		}
	}		
	@media(max-width: 767px){
		.reviews-holder{
			padding: 0 0 30px;
			.hwcrJd{
				overflow: hidden;
				padding: 30px;
				margin-bottom: -50px;
				.fctGyX{
					flex-wrap: nowrap;
					margin-bottom: 15px;
				}
			}
		}
		.looking-more-customer{
			padding: 30px 0;
			h1{
				font-size:24px;
				margin: 0 0 15px;
			}
			p{
				font-size: 16px;
			}
			.img-holder{
				margin-top: 30px;
				a{
					margin: 5px;
					img{
						max-height: 50px;
					}
				}
			}
		}
		.we-want-hear-from-you{
			padding: 30px 0;
			h1{
				font-size:24px;
				margin: 0 0 15px;
			}
			p{
				font-size: 16px;
			}
			.img-holder{
				margin-top: 30px;
			}
		}
	}
}
