@import "variables";
.become-a-partner-page{
	.top-banner{
		background: $pricing-top-banner-linear-gradient;
		padding: 75px 0 0;
		.banner-caption{
			margin-bottom: 150px;
			h1{
				font-weight: 700;
				font-size: 42px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
		}
		.banner-holder{
			img{
				position: relative;
				right: -5%;
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
			}
		}
		@media(max-width: 1199px){
			padding: 50px 0 150px;
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
			}
		}
		@media(max-width: 991px){
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 100px;
			.banner-caption{
				text-align: center;
				margin: 30px 0 0;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
			}
			.banner-holder{
				img{
					right: 0;
				}
			}
		}	
	}
	.benefits-becoming-partner{
		padding: 0;
		background: $white-color;
		.dark-box-top{
			background: $dark-box-bg;
			padding: 55px 45px;
			border-radius: 5px;
			position: relative;
			top: -150px;
			margin-bottom: -100px;
			color: $white-color;
			h4{
				font-weight: 700;
				font-size: 24px;
				margin: 0;
			}
			p{
				font-size: 18px;
				margin: 0;
			}
			.box-l{
				padding-right: 45px;
				border-right: 1px solid rgba($color: $white-color, $alpha: 0.1);
			}
			.box-r{
				padding-left: 45px;
			}
		}
		.content-box{
			padding: 50px 0;
			.content{
				h1{
					margin: 0 0 30px;
					font-weight: 700;
					font-size: 36px;
				}
				p{
					font-size: 18px;
					margin: 0 0 30px;
				}
				h5{
					font-weight: 700;
					font-size: 16px;
					margin: 0 0 25px;
				}
				ul{
					list-style: none;
					margin: 0 0 30px;
					padding: 0;
					display: flex;
					gap: 15px;
					flex-wrap: wrap;
					justify-content: space-between;
					li{
						font-size: 16px;
						padding-left: 30px;
						position: relative;
						width: 47%;
					}
					  li:before {
						content: url('../images/icons/check-circle-green.png');
						position: absolute;
						top: 2px;
						left: 0;
					}
				}
			}
		}
		@media(min-width: 1400px){
			.dark-box-top{
				h4{
					font-size: 28px;
				}
				p{
					font-size: 22px;
				}
			}
			.content-box{
				.content{
					h1{
						font-size: 40px;
					}
					p{
						font-size: 22px;
					}
					h5{
						font-size: 18px;
					}
					ul{
						li{
							font-size: 18px;
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			.content-box{			
				.content{
					h1{
						font-size: 31px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.dark-box-top{
				h4{
					font-size: 18px;
				}
				p{
					font-size: 16px;
				}
				.box-l{
					padding-right: 30px;
				}
				.box-r{
					padding-left: 30px;
				}
			}
			.content-box{				
				padding: 30px 0;
				.content{					
					ul{
						display: block;
						li{
							margin: 0 0 15px;
							width: 100%;
						}
					}
				}
			}
		}
		@media(max-width: 767px){
			.dark-box-top{
				padding: 20px;
				top: -75px;
				margin-bottom: -50px;
				.box-l{
					padding-right: 15px;
					border-right: 0;
					margin-bottom: 15px;
				}
				.box-r{
					padding-left: 15px;
				}
			}	
			.content-box{				
				padding: 30px 0;
				text-align: center;
				.content{
					margin-bottom: 15px;
					h1{
						font-size: 24px;
						margin: 0 0 15px;
					}
					p{
						font-size: 16px;
						margin: 0 0 15px;
						
					}
					ul{
						text-align: left;
						margin: 0 0 15px;
					}
				}
			}
		}
	}
	.how-it-works{
		background: $integrations-content-linear-gradient;
		padding: 100px 0;
		.hiw-content-holder{
			margin-bottom: 50px;
			text-align: center;
			h5{
				color: $anchor-color;
				font-weight: 700;
				text-transform: uppercase;
				font-size: 22px;
				margin: 0 0 30px;
			}
			h1{
				font-weight: 700;
				font-size: 31px;
				margin: 0 0 50px;
			}
			.steps-holder{
				text-align: center;
				margin-bottom: 30px;
				position: relative;
				.steps{
					position: relative;
					background: url("../images/icons/arrow.png") no-repeat right top 47px;
					img{
						max-width: 120px;
						margin-bottom: 20px;
					}
					h4{
						font-weight: 700;
						font-size: 18px;
						margin-bottom: 15px;
					}
					p{
						font-size: 16px;
					}
				}
				.steps:last-child{
					background: none;
				}
			}
			.steps-holder:before{
				background: $black-color;
				content: "";
				position: absolute;
				width: 75%;
				left: 12%;
				height: 2px;
				top: 24%;
			}
		}
		.form-box-holder{
			p{
				font-size: 21px;
				text-align: center;

			}
			.form-box{				
				margin-top: 50px;
				padding: 50px;
				background: $white-color;
				.box{
					h4{
						font-weight: 700;
						font-size: 18px;
						color: $default-color;
						margin-bottom: 50px;
					}
					.input-box{
						margin-bottom: 45px;
						label{
							font-size: 14px;
							font-weight: 400;
							margin-bottom: 0;
						}
						.form-control{
							border-width: 0 0 1px;
							border-radius: 0;
							background: transparent;
							box-shadow: none;
							padding-left: 0;
							padding-right: 0;
							color: $default-color;
							font-size: 14px;
						}
						.form-control:focus{
							border-color: $anchor-color;
						}
						.radio-box{
							label{
								display: inline-block;
								border-radius: 10px;
								margin-right: 30px;
								input{
									visibility: hidden;
									position: absolute;
								}
								input + div{ 
									cursor:pointer;
									background: url('../images/icons/radio-icon.png') no-repeat left center;
									padding: 10px 10px 10px 30px;
									color: $default-color;
									font-size: 14px;
									font-weight: 400;
								}
								input:checked + div{
									background: url('../images/icons/radio-check-icon.png') no-repeat left center;
								}
							}
							label:last-child{
								margin-right: 0;
							}
						}
					}
					.btn-holder{
						display: flex;
						justify-content: center;
						margin-top: 30px;
						gap: 15px;
						.btn-reset{
							background: $white-color;
							color: $default-color;
							text-transform: uppercase;
							height: 40px;
							border: 1px solid $default-color;
							border-radius: 4px;
							font-size: 16px;
							width: 125px;
							@media(max-width: 767px){
								font-size: 14px;
							}
						}
						.btn-submit{
							background: $anchor-color;
							color: $white-color;
							text-transform: uppercase;
							height: 40px;
							border-radius: 4px;
							font-size: 16px;
							font-weight: 500;
							border: 1px solid $anchor-color;
							width: 125px;
							@media(max-width: 767px){
								font-size: 14px;
							}
						}
						.btn:hover{
							opacity: .8;
						}
					}
				}
			}
		}
		@media(min-width: 1400px){
			.hiw-content-holder{
				h5{
					font-size: 24px;
				}
				h1{
					font-size: 36px;
				}
				.steps-holder{
					.steps{
						background: url("../images/icons/arrow.png") no-repeat right top 51px;						
						h4{
							font-size: 22px;
						}
						p{
							font-size: 18px;
						}
					}
				}
			}
			.form-box-holder{
				p{
					font-size: 24px;
	
				}
				.form-box{				
					.box{
						h4{
							font-size: 22px;
						}
						.input-box{
							label{
								font-size: 16px;
							}
							.form-control{
								font-size: 16px;
							}
							.radio-box{
								label{
									input + div{ 
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 75px 0;
			.hiw-content-holder{
				margin-bottom: 15px;
				h5{
					font-size: 18px;
					margin: 0 0 15px;
				}
				h1{
					font-size: 28px;
				}
				.steps-holder:before{
					top: 22%;
				}
			}
			.form-box-holder{
				p{
					font-size: 18px;
	
				}
				.form-box{				
					.box{
						h4{
							font-size: 18px;
						}
					}
				}
			}
		}
		@media(max-width: 991px){
			padding: 50px 0;
			.hiw-content-holder{
				margin-bottom: 0;
				h5{
					font-size: 18px;
					margin: 0 0 15px;
				}
				h1{
					font-size: 24px;
				}
				.steps-holder{
					margin-bottom: 15px;
					.steps{
						background: none;
						margin-bottom: 15px;
					}
				}
				.steps-holder:before{
					display: none
				}
			}
		}
		@media(max-width: 767px){
			padding: 30px 0;
			.form-box-holder{
				p{
					font-size: 18px;
					text-align: center;
	
				}
				.form-box{				
					margin-top: 30px;
					padding: 20px;
					.box{
						h4{
							font-size: 16px;
							margin-bottom: 30px;
						}
						.input-box{
							margin-bottom: 30px;
						}
						.btn-holder{
							margin-top: 15px;							
						}
					}
				}
			}
		}
	}
	.dft-content-holder{
		background: $white-color;
		padding: 50px 0;
		.content-box{
			padding: 50px 0;
			.content{
				h1{
					margin: 0 0 30px;
					font-weight: 700;
					font-size: 36px;
				}
				p{
					font-size: 18px;
					margin: 0 0 30px;
				}
				h5{
					font-weight: 700;
					font-size: 16px;
					margin: 0 0 25px;
				}
				ul{
					list-style: none;
					margin: 0 0 30px;
					padding: 0;
					li{
						font-size: 16px;
						padding-left: 30px;
						position: relative;
						margin-bottom: 15px;
					}
					li:before {
						content: url('../images/icons/check-circle-green.png');
						position: absolute;
						top: 2px;
						left: 0;
					}
				}
				.need-help{
					display: flex;
					align-items: center;
					gap: 15px;
					background: $need-help-box-bg;
					border-radius: 53px;
					padding: 15px 20px;
					margin: 0 0 30px;
					img{
						max-height: 35px;
					}
					h4{
						font-style: italic;
						font-weight: 700;
						font-size: 16px;
						margin: 0 0 5px;
					}
					p{
						font-size: 14px;
						font-style: italic;
						margin: 0;
					}
				}
				.get-started-btn{
					font-size: 16px;
					font-weight: 700;
					display: flex;
					align-items: center;
					margin: 0 0 30px;
					i{
						margin-left: 10px;
						font-size: 18px;
					}
				}
			}
		}
		@media(min-width: 1400px){
			.content-box{
				.content{
					h1{
						font-size: 40px;
					}
					p{
						font-size: 22px;
					}
					h5{
						font-size: 18px;
					}
					ul{
						li{
							font-size: 18px;
						}
					}
					.need-help{
						h4{
							font-size: 18px;
						}
						p{
							font-size: 16px;
						}
					}
					.get-started-btn{
						font-size: 18px;
					}
				}
			}
		}
		@media(max-width: 1199px){
			.content-box{
				.content{
					h1{
						font-size: 31px;
					}
				}
			}
		}
		@media(max-width: 991px){
			padding: 30px 0;
			.content-box{				
				padding: 30px 0;
			}
		}
		@media(max-width: 767px){
			padding: 15px 0;
			text-align: center;
			.content-box{				
				padding: 30px 0;
				.content{
					margin-bottom: 15px;
					h1{
						font-size: 24px;
						margin: 0 0 15px;
					}
					p{
						font-size: 16px;
						margin: 0 0 15px;
					}
					ul{
						text-align: left;
						margin: 0 0 15px;
					}
					.need-help{
						display: block;
						margin: 0 0 15px;
						img{
							max-height: 45px;
							margin: 0 0 10px;
						}
					}
					.get-started-btn{
						display: inline-flex;
					}
				}
			}
		}
	}
}
