body,html {
    font-family: $default-font;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: $default-color;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: $default-bg;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none; 
    }    
    ::-webkit-scrollbar-thumb {
        background-color: $default-scroll-bar-color;
        -webkit-box-shadow: none;
        border: 0;
        border-radius: 10px; 
    }
    ::-webkit-scrollbar-thumb:hover{
        background-color: $default-scroll-bar-hvr-color; 
    }
    a{
        text-decoration: none;
        color: $anchor-color;
        transition: color .5s;
    }
    a:hover{
        color: $anchor-hvr-color;
    }
    .bg-white{
        background: $white-color !important;
    }
    .ftr-need-help-holder{
        background: $ftr-top-need-help-bg;
        padding: 0;
        .img-holder{
            padding-right: 50px;
        }
        .content-holder{
            margin: 75px 0;
            h4{
                font-size: 18px;
                font-weight: 700;
                color: $nav-hvr-color;
                text-transform: uppercase;
                margin: 0 0 20px;
            }
            h1{
                font-size: 36px;
                font-weight: 700;
                margin: 0 0 20px;
            }
            p{
                font-size: 18px;
                margin: 0 0 20px;
            }
            .btn-flx-holder{
                display: flex;
                padding: 30px 0 0;
                gap: 50px;
                align-items: center;
                .view-more-btn{
                    width: 280px;
                    height: 60px;
                    background: $black-color;
                    border-radius: 5px;
                    color: $white-color;
                    font-weight: 700;
                    font-size: 18px;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-position: center;
                    border: 1px solid $black-color;
                    transition: background 0.8s;
                }
                .view-more-btn:hover {
                  background: $ripple-btn-dflt-hvr;
                  color: $black-color;
                }
                .view-more-btn:active {
                  background-color: transparent;
                  background-size: 100%;
                  transition: background 0s;
                }
                .phone{
                    font-size: 18px;
                    font-weight: 700;
                    .or{
                        padding-right: 10px;
                    }
                }
            }
        }
    }
    footer{
        .footer-main{
            padding: 75px 0 45px;
            background: $white-color;
            .ftr-logo-holder{
                .logo-holder{
                    margin-bottom: 20px;
                    a{
                        img{
                            max-width: 119px;
                            max-height: 36px;
                        }
                        &:hover{
                            img{
                                opacity: .8;
                            }
                        }
                    }
                }
                p{
                    font-size: 14px;
                    color: $secondary-color;
                    opacity: .8;
                    margin: 0 0 20px;
                }
                .socail-icons{
                    list-style: none;
                    margin: 0 0 30px;
                    padding: 0;
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    li{
                        .twitter{
                            display: block;
                            width: 20px;
                            height: 17px;
                            background: url('../images/icons/twitter.png') no-repeat;
                            transition: background .3s;
                            &:hover{
                                background: url('../images/icons/twitter-hvr.png') no-repeat;
                            }
                        }
                        .facebook{
                            display: block;
                            width: 23px;
                            height: 23px;
                            background: url('../images/icons/facebook.png') no-repeat;
                            transition: background .3s;
                            &:hover{
                                background: url('../images/icons/facebook-hvr.png') no-repeat;
                            }
                        }
                        .linkedin{
                            display: block;
                            width: 21px;
                            height: 21px;
                            background: url('../images/icons/linkedin.png') no-repeat;
                            transition: background .3s;
                            &:hover{
                                background: url('../images/icons/linkedin-hvr.png') no-repeat;
                            }
                        }
                        .youtube{
                            display: block;
                            width: 20px;
                            height: 15px;
                            background: url('../images/icons/youtube.png') no-repeat;
                            transition: background .3s;
                            &:hover{
                                background: url('../images/icons/youtube-hvr.png') no-repeat;
                            }
                        }
                        .instagram{
                            display: block;
                            width: 20px;
                            height: 15px;
                            background: url('../images/icons/instagram.png') no-repeat;
                            transition: background .3s;
                            &:hover{
                                background: url('../images/icons/instagram_hvr.png') no-repeat;
                            }
                        }
                    }
                }
                .email-phone{
                    line-height: 30px;
                    a{
                        font-size: 16px;
                        color: $secondary-color;
                    }
                    .phone{
                        font-size: 16px;
                        font-weight: 700;
                        color: $anchor-hvr-color;
                    }
                }
            }
            .ftr-navs-holder{
                h4{
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin: 0 0 20px
                }
                ul{
                    margin: 0 0 30px;
                    padding: 0;
                    list-style: none;
                    li{
                        margin-bottom: 15px;
                        font-size: 14px;
                        a{
                            color: $secondary-color;
                            transition: color .3s;
                        }
                        a:hover{
                            color: $nav-hvr-color;
                        }
                    }
                }
            }
        }
        .footer-copyright{
            background: $nav-toggler-bg;
            padding: 28px 0;
            text-align: center;
            p{
                color: $secondary-color;
                font-size: 14px;
                margin: 0;
            }
        }
    }
    @media(min-width: 1400px){
        font-size: 16px;
        .ftr-need-help-holder{
            .img-holder{
                padding-right: 75px;
            }
            .content-holder{
                margin: 100px 0;
                h4{
                    font-size: 22px;
                }
                h1{
                    font-size: 45px;
                }
                p{
                    font-size: 22px;
                }
                .btn-flx-holder{
                    .view-more-btn{
                        width: 350px;
                        height: 70px;
                        font-size: 22px;
                    }
                    .phone{
                        font-size: 22px;
                        .or{
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
        footer{
            .footer-main{
                .ftr-logo-holder{
                    p{
                        font-size: 16px;
                    }
                    .email-phone{
                        line-height: 35px;
                        a{
                            font-size: 18px;
                        }
                        .phone{
                            font-size: 18px;
                        }
                    }
                }
                .ftr-navs-holder{
                    h4{
                        font-size: 16px;
                    }
                    ul{
                        li{
                            font-size: 16px;
                        }
                    }
                }
            }
            .footer-copyright{
                p{
                    font-size: 16px;
                }
            }
        }
    }
    @media(max-width: 1199px){ 
        .ftr-need-help-holder{
            .img-holder{
                padding-right: 30px;
            }
            .content-holder{
                margin: 50px 0;
                h4{
                    font-size: 16px;
                    margin: 0 0 15px;
                }
                h1{
                    font-size: 31px;
                    margin: 0 0 15px;
                }
                p{
                    font-size: 16px;
                    margin: 0 0 15px;
                }
                .btn-flx-holder{
                    padding: 15px 0 0;
                    gap: 20px;
                    .view-more-btn{
                        width: 260px;
                        font-size: 16px;
                    }
                    .phone{
                        font-size: 16px;
                        .or{
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 991px){
        .ftr-need-help-holder{
            .content-holder{
                .btn-flx-holder{
                    flex-wrap: wrap;
                }
            }
        }
        footer{
            .footer-main{
                padding: 50px 0 20px;
                .ftr-logo-holder{
                    margin-bottom: 30px;
                    .logo-holder{
                        margin-bottom: 15px;
                    }
                    p{
                        margin: 0 0 15px;
                    }
                    .socail-icons{
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }
    @media(max-width: 767px){
        .ftr-need-help-holder{
            text-align: center;
            .img-holder{
                padding-right: 0;
            }
            .content-holder{
                margin: 50px 0 0;
                h1{
                    font-size: 24px;
                }
                .btn-flx-holder{
                    display: block;
                    margin-bottom: 15px;
                    .view-more-btn{
                        display: inline-flex;
                        height: 50px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    @media(max-width: 574px){
        .ftr-need-help-holder{
            .content-holder{
                margin: 30px 0 0;
            }
        }
        footer{
            .footer-main{
                padding: 30px 0 0;
                .ftr-logo-holder{
                    margin-bottom: 15px;
                }
            }
            .footer-copyright{
                padding: 20px 0;
                p{
                    font-size: 14px;
                }
            }
        }
    }
}