@import "variables";
.integrations-page{
	.top-banner{
		background: $integrations-top-banner-linear-gradient;
		padding: 50px 0 85px;
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 42px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
			.btn-holder{
				margin: 45px 0 0;
				.btn-become-partner{
					height: 60px;
					background: transparent;
					border-radius: 5px;
					color: $black-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;					
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $black-color;
					background-position: center;
      				transition: background 0.8s;
				}
			    .btn-become-partner:hover {
					background: $black-color;
					color: $white-color;
			    }
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
				.btn-holder{
					margin: 50px 0 0;
					.btn-become-partner{
						height: 70px;
						font-size: 21px;
					}
				}
			}
		}
		@media(max-width: 1199px){
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
				.btn-holder{
					margin: 30px 0 0;
					.btn-become-partner{
						height: 60px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
				.btn-holder{
					.btn-become-partner{
					    height: 50px;
					    font-size: 16px;
					}
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 50px;
			.banner-caption{
				text-align: center;
				margin: 30px 0;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
				.btn-holder{
					margin-top: 25px;
				}
			}
		}
	}
	.integrations-holder{
		padding: 0 0 50px;
		background: $integrations-content-linear-gradient;
		.nav-tabs{
			margin: 0;
			position: relative;
			top: -38px;
			background: $nav-dark-bg;
			box-shadow: 0px 20px 50px $nav-dark-shadow;
			border-radius: 5px;
			border: 0;
			padding: 10px;
			.nav-link{
				font-size: 16px;
				font-weight: 700;
				padding: 5px 20px;
				color: $white-color;
				border-radius: 5px;
				margin: 5px 10px;
				display: flex;
				align-items: center;
				height: 40px;
				border: 0;
				&:hover{
					border: 0;
				}
			}
			.nav-link.active{
				background: $nav-dark-btn-bg;
				color: $white-color;
				border: 0;
			}
			@media(max-width: 1199px){
				.nav-link{
					font-size: 14px;
					padding: 5px 15px;
					margin: 5px;
				}
			}
			@media(max-width: 991px){
				.nav-link{
					font-size: 14px;
					padding: 5px 10px;
					margin: 0;
				}
			}
			@media(max-width: 767px){
				.nav-link{
					font-size: 14px;
					padding: 5px 15px;
				}
			}
		}
		.search-box-holder{
			display: flex;
			justify-content: flex-end;
			margin-bottom: 30px;
			.search-box{
	    		position: relative;	    		
				width: 260px;
				z-index: 1;
				.form-control{
				    border-radius: 4px;
				    height: 40px;
				    background: $white-color;
				    border: 1px solid $black-border-half-opacity;
				    padding: 0px 15px 0 35px;
				    font-size: 14px;
				    box-shadow: 0 0 30px $default-border;
				    font-weight: 700;
				}
				i{
					position: absolute;
				    left: 10px;
				    top: 9px;
				    font-size: 16px;
				}
			}
		}
		.box-title-holder{
			padding: 35px 20px;
			h2{
				color: $black-color;
				font-weight: 700;
				font-size: 24px;
				margin: 0;
			}			
			@media(max-width: 767px){
		    	padding: 20px;
				h2{
					font-size: 18px;
				}
			}
		}
		.box-title-holder:first-child{
			padding-top: 0;	
			margin-bottom: -65px;
			h2{
				position: relative;
				top: -65px;
			}
		}
		.box-holder-row{
			margin-left: -20px;
			margin-right: -20px;
			@media(max-width: 767px){
		    margin-left: 0;
				margin-right: 0;
			}
		}
		.box-holder{
			padding: 0 20px;
			margin-bottom: 40px;
			.box{
				background: $white-color;
				border-radius: 4px;
				padding: 30px 20px;
				box-shadow: 0 0 25px $default-border;
				height: 100%;
				position: relative;
				.content-holder{
					.flex-box{
						display: flex;
						.img-holder{
							text-align: center;
							min-width: 50px;
							img{
								max-width: 45px;
							}
						}	
						.content{
							padding-left: 20px;
							h4{
								font-size: 16px;
								color: $black-color;
								font-weight: 700;
								margin: 0 0 15px;
							}	
							p{
								font-size: 14px;
								margin: 0;
								strong{
									color: $black-color;
									font-weight: 700;
								}
							}					
							ul{
								font-size: 14px;
								margin: 0;
								padding: 0 0 0 15px;
							}	
						}
					}
				}
			}
		}
		@media(min-width: 1400px){
			.nav-tabs{
				.nav-link{
					font-size: 18px;
				}
			}
			.box-holder{
				.box{
					.content-holder{
						.flex-box{
							.content{
								h4{
									font-size: 20px;
								}	
								p{
									font-size: 16px;
								}					
								ul{
									font-size: 16px;
								}	
							}
						}
					}
				}
			}
		}
		@media(max-width: 767px){
			.search-box-holder{
				justify-content: center;
				.search-box{
	    		position: relative;	    		
					width: 250px;
				}
			}
			.box-title-holder:first-child{
				padding: 0 0 20px;
				h2{
					position: relative;
					top: 0;
				}
			}
			.box-holder {
		    padding: 0;
		    margin-bottom: 15px;
			}
		}
	}
}
