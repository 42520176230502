@import "variables";
.affiliate-faq-layout{
	.top-banner{
		background: $terms-bg;
		padding: 100px 0 250px;
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 40px;
				line-height: normal;
				margin: 0 0 30px;
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
			}
		}
		@media(max-width: 1199px){
			padding: 75px 0 225px;
			.banner-caption{
				h1{
					font-size: 36px;
				}
			}
		}
		@media(max-width: 991px){
			padding: 50px 0 160px;
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 26px;
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 175px;
			.banner-caption{
				text-align: center;
				margin-bottom: 30px;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
			}
		}
	}
	.content-holder{
		.container-box{
			background: $affiliate-container-bg;
			padding: 75px 75px 55px;
			position: relative;
			top: -230px;
			margin-bottom: -170px;
			.question-box{
				background: $white-color;
				margin-bottom: 20px;
				padding: 30px;
				display: flex;
				gap: 20px;
				min-height: 150px;
				.qstn-nb{
					background: $qstn-bg-bg;
					max-width: 70px;
					min-width: 70px;
					height: 35px;
					text-align: center;
					text-transform: uppercase;
					font-size: 20px;
					border-radius: 25px;
				}
				h4{
					font-size: 18px;
					font-weight: 700;
					margin: 0 0 15px;
				}
				p{
					font-size: 18px;
					margin: 0;
				}
			}			
		}
		@media(max-width: 1199px){
			.container-box{
				padding: 50px 50px 30px;
				top: -230px;
				margin-bottom: -170px;		
			}
		}		
		@media(max-width: 991px){
			.container-box{
				padding: 30px 30px 20px;
				top: -195px;
				margin-bottom: -170px;	
				.question-box{
					padding: 20px;
					gap: 15px;
					.qstn-nb{
						max-width: 65px;
						min-width: 65px;
						height: 30px;
						font-size: 18px;
					}
					h4{
						font-size: 16px;
					}
					p{
						font-size: 16px;
					}
				}	
			}
		}
		@media(max-width: 767px){
			.container-box{
				padding: 15px 15px 10px;
				.question-box{
					padding: 15px;
					gap: 10px;
					.qstn-nb{
						max-width: 50px;
						min-width: 50px;
						height: 27px;
						font-size: 16px;
					}
				}	
			}
		}
	}
}
