@import "variables";
.affiliate-layout{
	.top-banner{
		background: $terms-bg;
		padding: 75px 0 150px;	
		.banner-caption{
			margin-bottom: 30px;
			h1{
				font-weight: 700;
				font-size: 40px;
				line-height: normal;
				margin: 0 0 30px;
			}
			p{
				font-size: 21px;
			}
			.btn-holder{
				margin: 45px 0 0;
				display: flex;
				gap: 15px;
				position: relative;
				.btn-start-trial{
					height: 60px;
					background: transparent;
					border-radius: 5px;
					color: $black-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;					
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $black-color;
					background-position: center;
      				transition: background 0.8s;
				}
				.btn-start-trial:hover {
					background: $black-color;
					color: $white-color;
				}
				.watch-video-btn{
					width: 260px;
					height: 60px;
					background: $black-color;
					border-radius: 5px;
					color: $white-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $black-color;
					background-position: center;
      				transition: background 0.8s;
				}
			    .watch-video-btn:hover {
			      background: $ripple-btn-dflt-hvr;
                  color: $black-color;
			    }
			    .watch-video-btn:active {
			      background-color: transparent;
			      background-size: 100%;
			      transition: background 0s;
			    }
			}
		}
		@media(min-width: 1400px){
			.banner-caption{
				h1{
					font-size: 50px;
					margin: 0 0 40px;
				}
				p{
					font-size: 24px;
				}
				.btn-holder{
					margin: 50px 0 0;
					.watch-video-btn, .btn-start-trial{
						height: 70px;
						font-size: 21px;
					}
				}
			}
		}
		@media(max-width: 1199px){
			padding: 50px 0 150px;
			.banner-caption{
				h1{
					font-size: 36px;
				}
				p{
					font-size: 18px;
				}
				.btn-holder{
					margin: 30px 0 0;
					.watch-video-btn{
						width: 250px;
					}
				}
			}
		}
		@media(max-width: 991px){
			.banner-caption{
				margin-bottom: 50px;
				h1{
					font-size: 31px;
				}
				.btn-holder{
					.watch-video-btn{
						width: 230px;
					    height: 50px;
					    font-size: 16px;
					}
					.btn-start-trial{
						height: 50px;
					    font-size: 16px;
					}
				}
			}
		}		
		@media(max-width: 767px){
			padding: 30px 0 0;
			.banner-caption{
				text-align: center;
				margin-bottom: 30px;
				h1{
					font-size: 24px;
					margin-bottom: 15px;
				}
				p{
					font-size: 16px;
				}
				.btn-holder{
					display: block;
					margin: 25px 0 0;
					.watch-video-btn, .btn-start-trial{
						margin: 15px 0 0;
						width: 100%;
					}
				}
			}
		}
	}
	.content-holder{
		.container-box{
			background: $affiliate-container-bg;
			padding: 50px 50px 35px;
			position: relative;
			top: -230px;
			margin-bottom: -170px;
			.title-holder{
				text-align: center;
				margin: 0 0 50px;
				h1{
					font-weight: 700;
					font-size: 45px;
					margin: 0;
				}
			}
			.step-holder{
				.step{
					position: relative;					
					margin-bottom: 30px;
					padding: 80px 30px 0;					
					.step-content{
						height: 100%;
						background: $white-color;
						padding: 30px;
						font-size: 16px;
						ol{
							list-style: none;
							padding: 0;
							margin: 30px 0;
							counter-reset: step;
							li{
								margin: 0 0 15px;
								position: relative;
								padding: 0 0 0 40px;
								min-height: 30px;
							}
							li:before{
								content: counter(step);
  								counter-increment: step 1;
								position: absolute;
								left: 0;
								top: 2px;
								background: #cccccc;
								width: 25px;
								height: 25px;
								border-radius: 50%;
								text-align: center;
								line-height: 25px;
								font-size: 14px;
							}
						}
						h3{
							text-align: center;
							font-size: 18px;
							text-transform: uppercase;
							font-weight: 700;
						}
						h4{
							font-size: 18px;
							font-weight: 700;
							margin: 0 0 15px;
						}
						
					}
					.step-content.img-holder{
						padding: 0;
						display: flex;
						align-items: center;
					}
					.step-content.testimonial-holder{
						padding: 15px;
						.testimonial{
							position: relative;
							background: $white-color;							
							border-radius: 4px;
							padding: 0;
							height: 100%;
							display: flex;
							align-items: center;
							.carousel-item{
								background: $white-color;
								.content{					
									padding: 15px 50px 0;
									text-align: center;
									font-size: 14px;		
									a{
										font-weight: 700;
										color: $default-color;
									}		
								}
								.info{
									margin-top: 20px;
									text-align: center;
									h4{
										font-size: 14px;
										text-transform: uppercase;
										margin: 0 0 5px;
										font-weight: 700;
									}
									p{
										margin: 0;
										font-size: 14px;
									}
								}
							}
							.carousel-ctrl-prev, .carousel-ctrl-next{
								font-size: 21px;
								width: 30px;
								position: absolute;
								height: 30px;
								top: 45%;
								opacity: .7;
								color: $default-color;
								z-index: 1;
							}
							.carousel-ctrl-prev{
								left: 15px;
							}
							.carousel-ctrl-next{
								right: 15px;
							}
							.carousel-ctrl-prev:hover, .carousel-ctrl-next:hover{
								opacity: 1;
							}
						}
					}
				}
				.step1{
					.label-holder{
						position: absolute;
						right: -3.5px;
						top: 0;
						label{
							height: 45px;
							background: $anchor-color;
							border-radius: 25px 25px 0px 25px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 18px;
							color: $white-color;
							padding: 0 30px;
							gap: 10px;
							span{
								font-weight: 700;
								font-size: 22px;
							}
						}
					}
				}
				.step2{
					.label-holder{
						position: absolute;
						left: -3.5px;
						top: 0;
						label{
							height: 45px;
							background: #5CBC63;
							border-radius: 25px 25px 25px 0;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 18px;
							color: $white-color;
							padding: 0 30px;
							gap: 10px;
							span{
								font-weight: 700;
								font-size: 22px;
							}
						}
					}
				}
				.step3{
					.label-holder{
						position: absolute;
						right: -3.5px;
						top: 0;
						label{
							height: 45px;
							background: #DBA65D;
							border-radius: 25px 25px 0px 25px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 18px;
							color: $white-color;
							padding: 0 30px;
							gap: 10px;
							span{
								font-weight: 700;
								font-size: 22px;
							}
						}
					}
				}
				.step.step1::before{
					content: "";
					background: $anchor-color;
					position:absolute;
					right: -3.5px;
					width: 7px;
					top: 25px;
					bottom: 0;
				}				
				.step.step2::before{
					content: "";
					background: #5CBC63;
					position:absolute;
					left: -3.5px;
					width: 7px;
					top: 25px;
					bottom: 0;
				}
				.step.step3::before{
					content: "";
					background: #DBA65D;
					position:absolute;
					right: -3.5px;
					width: 7px;
					top: 25px;
					bottom: 0;
				}
				.slidecontainer {
					width: 100%;
					position: relative;
					text-align: center;
					margin: 30px 0;
					.plan-price-details{
						.commission-count{
							font-size: 45px;
							font-weight: 700;
							margin: 0 0 15px;
							.month{
								font-size: 18px;
								font-weight: 400;
							}
						}
					}
					p{
						font-size: 16px;
						margin-bottom: 30px;
					}
					input[type="range"]{
						-webkit-appearance: none; 
						appearance: none;
						width: 100%;
						min-width: 150px;
						height: 4px; 
						-webkit-appearance: none;
						-moz-apperance: none;
						outline: 0;
						background-image: -webkit-gradient(
							linear,
							left top,
							right top,
							color-stop(0, $range-slider-thumb),
							color-stop(0, #EDEDED)
						);
					}
					input[type='range']::-webkit-slider-thumb {
						-webkit-appearance: none; 
						appearance: none;
						width: 24px; 
						height: 24px; 
						background: url('../images/icons/range-thumb.png') no-repeat center $range-slider-thumb;
						cursor: pointer; 
						outline: 0;
						border-radius: 50%;
					}
					.affiliate-slider::-moz-range-thumb {
						width: 24px; 
						height: 24px; 
						background: $range-slider-thumb; 
						cursor: pointer; 
						border-radius: 50%;
						outline: 0;
					}
				}
				.info_box {
					position: relative;
					background: #EDEDED;
					text-align: left;
					border-radius: 4px;
					padding: 8px 15px;
					font-size: 14px;
					font-weight: 500;
					color: #606060;
				}
				.info_box:after, .arrow_box:before {
					bottom: 100%;
					left: 20px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}

				.info_box:after {
					border-color: rgba(230, 235, 239, 0);
					border-bottom-color: #EDEDED;
					border-width: 10px;
					margin-left: -10px;
				}
				.info_box:before {
					border-color: rgba(230, 235, 239, 0);
					border-bottom-color: #EDEDED;
					border-width: 11px;
					margin-left: -11px;
				}
			}		
		}
		@media(max-width: 1199px){
			.container-box{
				padding: 45px 30px 15px;
				top: -180px;
				margin-bottom: -150px;	
				.title-holder{
					h1{
						font-size: 36px;
					}
				}
				.step-holder{
					.slidecontainer {
						.plan-price-details{
							.commission-count{
								font-size: 36px;
								.month{
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}		
		@media(max-width: 991px){
			.container-box{
				padding: 30px 30px 20px;
				top: -175px;
				margin-bottom: -170px;	
				.title-holder{
					h1{
						font-size: 31px;
					}
				}
			}
		}
		@media(max-width: 767px){
			.container-box{
				padding: 30px 15px 10px;
				top: -100px;
				margin-bottom: -70px;	
				.title-holder{
					margin: 0 0 30px;
					h1{
						font-size: 24px;
					}
				}
				.step-holder{
					.step{
						padding: 15px 15px 0;
						margin: 0 0 15px;					
						.step-content{
							padding: 20px;
							ol{
								margin: 20px 0;
								font-size: 14px;
							}
							h3{
								font-size: 16px;
							}
							h4{
								font-size: 16px;
							}
							p{
								font-size: 14px;
							}							
						}
						.step-content.testimonial-holder{
							padding: 15px;
							.testimonial{
								.carousel-item{
									.content{					
										padding: 15px 15px 0;
										font-size: 12px;	
									}
									.info{
										margin-top: 15px;
										h4{
											font-size: 12px;
										}
										p{
											font-size: 12px;
										}
									}
								}
								.carousel-ctrl-prev{
									left: -10px;
								}
								.carousel-ctrl-next{
									right: -10px;
									text-align: right;
								}
							}
						}
					}
					.step1{
						padding-top: 60px;
						.label-holder{
							label{
								height: 40px;
								font-size: 16px;
								padding: 0 20px;
								gap: 8px;
								span{
									font-size: 18px;
								}
							}
						}
					}
					.step2{
						padding-top: 60px;
						.label-holder{
							label{
								height: 40px;
								font-size: 16px;
								padding: 0 20px;
								gap: 8px;
								span{
									font-size: 18px;
								}
							}
						}
					}
					.step3{
						padding-top: 60px;
						.label-holder{
							label{
								height: 40px;
								font-size: 16px;
								padding: 0 20px;
								gap: 8px;
								span{
									font-size: 18px;
								}
							}
						}
					}
					.slidecontainer {
						width: 100%;
						position: relative;
						text-align: center;
						margin: 30px 0;
						.plan-price-details{
							.commission-count{
								font-size: 45px;
								font-weight: 700;
								margin: 0 0 15px;
								.month{
									font-size: 18px;
									font-weight: 400;
								}
							}
						}
						p{
							font-size: 16px;
							margin-bottom: 30px;
						}
						input[type="range"]{
							-webkit-appearance: none; 
							appearance: none;
							width: 100%;
							min-width: 150px;
							height: 4px; 
							-webkit-appearance: none;
							-moz-apperance: none;
							outline: 0;
							background-image: -webkit-gradient(
								linear,
								left top,
								right top,
								color-stop(0, $range-slider-thumb),
								color-stop(0, #EDEDED)
							);
						}
						input[type='range']::-webkit-slider-thumb {
							-webkit-appearance: none; 
							appearance: none;
							width: 24px; 
							height: 24px; 
							background: url('../images/icons/range-thumb.png') no-repeat center $range-slider-thumb;
							cursor: pointer; 
							outline: 0;
							border-radius: 50%;
						}
						.affiliate-slider::-moz-range-thumb {
							width: 24px; 
							height: 24px; 
							background: $range-slider-thumb; 
							cursor: pointer; 
							border-radius: 50%;
							outline: 0;
						}
					}
					.info_box {
						position: relative;
						background: #EDEDED;
						text-align: left;
						border-radius: 4px;
						padding: 8px 15px;
						font-size: 14px;
						font-weight: 500;
						color: #606060;
					}
					.info_box:after, .arrow_box:before {
						bottom: 100%;
						left: 20px;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
					}
	
					.info_box:after {
						border-color: rgba(230, 235, 239, 0);
						border-bottom-color: #EDEDED;
						border-width: 10px;
						margin-left: -10px;
					}
					.info_box:before {
						border-color: rgba(230, 235, 239, 0);
						border-bottom-color: #EDEDED;
						border-width: 11px;
						margin-left: -11px;
					}
				}	
			}
		}
	}
	.start-earning{
		background: $white-color;
		margin-bottom: 65px;
		.container-box{
			background: $start-earning-bg;
			padding: 75px 65px;
			color: $white-color;
			h4{
				font-weight: 700;
				text-transform: uppercase;
				font-size: 18px;
				margin: 0 0 30px;
			}
			h1{
				font-weight: 700;
				font-size: 40px;
				margin: 0 0 30px;
			}
			p{
				font-size: 18px;
			}
			.btn-holder{
				margin: 45px 0 0;
				display: flex;
				gap: 15px;
				position: relative;
				.btn-join{
					height: 60px;
					background: transparent;
					border-radius: 5px;
					color: $white-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;					
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $white-color;
					background-position: center;
      				transition: background 0.8s;
				}
				.btn-join:hover {
					background: $white-color;
              		color: $anchor-color;
				}
				.btn-faq{
					height: 60px;
					background: $black-color;
					border-radius: 5px;
					color: $white-color;
					font-weight: 700;
					font-size: 18px;
					padding: 0 30px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid $black-color;
					background-position: center;
				}
				.btn-faq:hover {
					background: $ripple-btn-dflt-hvr;
                  	color: $white-color;
					border: 1px solid $white-color;
				}
			}
		}
		@media(min-width: 1400px){
			.container-box{
				h4{
					font-size: 21px;
				}
				h1{
					font-size: 45px;
				}
				p{
					font-size: 21px;
				}
				.btn-holder{
					.btn-join, .btn-faq{
						height: 70px;
						font-size: 21px;
					}
				}
			}
		}
		@media(max-width: 1199px){
			.container-box{
				padding: 50px;
				margin-bottom: 50px;
				h1{
					font-size: 36px;
				}
			}
		}
		@media(max-width: 991px){
			.container-box{
				h4{
					margin-bottom: 15px;
				}
				h1{
					font-size: 31px;
				}
				.btn-holder{
					.btn-join, .btn-faq{
						height: 50px;
						font-size: 16px;
					}
				}
			}
		}	
		@media(max-width: 767px){
			margin: 0 0 30px;
			.container-box{
				padding: 30px 15px;
    			margin-bottom: 30px;
				h4{
					font-size: 16px;
					margin: 0 0 10px;
				}
				h1{
					font-size: 24px;
					margin: 0 0 15px;
				}
				p{
					font-size: 16px;
				}
				.btn-holder{
					display: block;
					margin: 15px 0;
					.btn-join, .btn-faq{
						margin: 15px 0 0;
						justify-content: center;
					}
				}
			}
		}	
	}
}
